const en = {
  TeacherReport: {
    Title: "Module Progress",
    Students: "Students",
    Total: "Total",
    HeaderTest: "Test",
    HeaderNormal: "Learning",
    HeaderCompleted: "Completed",
    HeaderResult: "Result",
    Blocks: "Blocks",
    StudentSelect: "Select student",
    DeleteReport: "Are you sure?",
    DeleteTooltip: "Remove",
    DownloadTooltip: "Download",
  },
  BlockTypes: {
    Vocabulary: "Words",
    Video: "Video",
    Grammar: "Grammar",
    Reading: "Reading",
    Writting: "Writting",
    Speaking: "Speaking",
    Test: "Test",
  },
  Loading: {
    Error: "Loading error, try refreshing the page",
  },
  SteppedProgress: {
    Completed: "Completed",
  },
};

export default en;
