import cn from "classnames";

import { Text } from "../../Text";

import styles from "./PercentageProgressBar.module.scss";

export interface PercentageProgressBarProps {
  type: "percent";
  subtitle?: string;
  progress?: number;
  subtitleAlign?: SubtitleAlign;
  barColor?: BarColor;
  backgroundColor?: BackgroundColor;
}

type SubtitleAlign = "left" | "center" | "right";

type BarColor = "green" | "blue";

type BackgroundColor = "white" | "grey";

export const PercentageProgressBar = function PercentageProgressBar(
  props: PercentageProgressBarProps
) {
  const {
    subtitle,
    subtitleAlign = "center",
    barColor = "green",
    backgroundColor = "gray",
    progress = 0,
  } = props;

  const progressClasses = cn(styles["progress"], styles[backgroundColor]);
  const progressBarClasses = cn(styles["progress-bar"], styles[barColor]);

  const subtitleClasses = cn(styles.subtitle, styles[subtitleAlign]);

  return (
    <>
      <div className={progressClasses}>
        <div
          role="progressbar"
          className={progressBarClasses}
          aria-valuenow={80}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: `${progress}%` }}
        />
      </div>
      <Text
        variant="text-4-bold"
        color="base-d"
        containerClassName={subtitleClasses}
      >
        {subtitle} {progress.toFixed(2)}%
      </Text>
    </>
  );
};
