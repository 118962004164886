import { FC } from "react";

import { ThemeProvider, CssBaseline } from "@mui/material";
import { observer } from "mobx-react-lite";
import { I18nextProvider } from "react-i18next";

import { defaultTheme } from "@packages/shared/themes/default";

import i18n from "../locales/i18n";

export const withProviders = (Component: FC<any>) => {
  return observer((props: any) => {
    return (
      <ThemeProvider theme={defaultTheme}>
        <I18nextProvider i18n={i18n}>
          <Component {...props} />
        </I18nextProvider>

        <CssBaseline />
      </ThemeProvider>
    );
  });
};
