import { useState } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

import { useGetBlock } from "@packages/data/blocks";
import { Block } from "@packages/data/courses";

import { Icon } from "../Icon";
import { Loading } from "../Loading";
import { Text } from "../Text";
import { Tooltip } from "../Tooltip";

import styles from "./ReportDetailsBlock.module.scss";

interface ReportDetailsBlockProps {
  data: Block;
  precessing: number;
  progress: number;
  getExerciseProcessing: (exerciseId: string) => number;
}

Modal.setAppElement("body");

export const ReportDetailsBlock = (props: ReportDetailsBlockProps) => {
  const { data, precessing, progress, getExerciseProcessing } = props;
  const { id, name, type } = data;

  const { t } = useTranslation();

  const { data: block, isLoading, isError } = useGetBlock(id);

  const [isOpen, setIsOpen] = useState(false);

  const arrowClassName = cn(styles.arrow, {
    [styles["arrow-open"]]: isOpen,
  });

  const toggle = () => setIsOpen((p) => !p);

  return (
    <>
      <div className={styles.container} onClick={toggle}>
        <Loading loading={isLoading} error={isError}>
          <div className={styles.name}>
            <div>
              <Text variant="text-3-link" color="accsent-1wd">
                {name}
              </Text>
              <Text variant="text-3-regular"> ({t(`BlockTypes:${type}`)})</Text>
            </div>

            <div className={styles.left}>
              <div className={styles.results}>
                <Tooltip title={t("TeacherReport:HeaderCompleted")}>
                  <Text variant="text-2-semibold">{progress}%</Text>
                </Tooltip>
                <Tooltip title={t("TeacherReport:HeaderResult")}>
                  <Text variant="text-2-semibold">{precessing}%</Text>
                </Tooltip>
              </div>

              <Icon icon="arrow-down" containerClassName={arrowClassName} />
            </div>
          </div>
        </Loading>
      </div>

      {isOpen && (
        <div className={cn(styles.container, styles.exercises)}>
          {block?.exercises.map((exercise) => (
            <div className={styles.name} key={exercise.id}>
              <Text variant="text-3-link" color="accsent-1wd">
                {exercise.name}
              </Text>

              <Text variant="text-2-semibold">
                {getExerciseProcessing(exercise.id)}%
              </Text>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
