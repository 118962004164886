import { ReactNode } from "react";

import cn from "classnames";

import styles from "./BlockWrapper.module.scss";

interface BlockWrapperProps {
  containerClassName?: string;
  children?: ReactNode | ReactNode[];
}

export const BlockWrapper = function BlockWrapper(props: BlockWrapperProps) {
  const { containerClassName, children } = props;

  const containerClasses = cn(containerClassName, styles.container);

  return <div className={containerClasses}>{children}</div>;
};
