import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";

import { WordFilter, WordFilterType } from "../../services/Api";
import { withEnvironment } from "../extensions/withEnvironment";

export const emptyFilterItem = "none";

export const WordFilterStoreModel = types
  .model("WordFilterStore")
  .props({
    fromUnit: types.optional(types.boolean, true),
    addedToMe: types.optional(types.boolean, false),
    selectedCoursesLineup: types.optional(types.string, emptyFilterItem),
    selectedCourse: types.optional(types.string, emptyFilterItem),
    selectedUnit: types.optional(types.string, emptyFilterItem),
    selectedType: types.optional(
      types.enumeration<WordFilterType>("WordFilterType", [
        "learned",
        "all",
        "unlearned",
      ]),
      "all"
    ),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    rememberFilterParam: flow(function* (key: string, value: string | boolean) {
      yield self.environment.api.rememberWordFilterParam(key, value);
    }),
  }))
  .actions((self) => ({
    rememberFromUnit: (fromUnit: boolean) => {
      self.fromUnit = fromUnit;
      self.rememberFilterParam("from_unit", fromUnit);
    },
  }))
  .actions((self) => ({
    rememberAddedToMe: (addedToMe: boolean) => {
      self.addedToMe = addedToMe;
      self.rememberFilterParam("added_to_me", addedToMe);
    },
  }))
  .actions((self) => ({
    rememberSelectedUnit: (selectedUnit: string) => {
      self.selectedUnit = selectedUnit;
      self.rememberFilterParam("selected_unit", selectedUnit);
    },
  }))
  .actions((self) => ({
    rememberSelectedCourse: (selectedCourse: string) => {
      self.selectedCourse = selectedCourse;
      self.rememberFilterParam("selected_course", selectedCourse);
      self.rememberSelectedUnit(emptyFilterItem);
    },
  }))
  .actions((self) => ({
    rememberSelectedCoursesLineup: (selectedCoursesLineup: string) => {
      self.selectedCoursesLineup = selectedCoursesLineup;
      self.rememberFilterParam(
        "selected_courses_lineup",
        selectedCoursesLineup
      );

      self.rememberSelectedCourse(emptyFilterItem);
      self.rememberSelectedUnit(emptyFilterItem);
    },
    rememberSelectedType: (selectedType: WordFilterType) => {
      self.selectedType = selectedType;
      self.rememberFilterParam("selected_type", selectedType);
    },
  }))
  .actions((self) => ({
    clear: () => {
      self.rememberFromUnit(true);
      self.rememberAddedToMe(false);
      self.rememberSelectedCoursesLineup(emptyFilterItem);
      self.rememberSelectedCourse(emptyFilterItem);
      self.rememberSelectedUnit(emptyFilterItem);
    },
  }))
  .actions((self) => ({
    addItem: (item: WordFilter) => {
      const { fromUnit, addedToMe, selectedCourse, selectedUnit } = item;
      self.fromUnit = !!fromUnit;
      self.addedToMe = !!addedToMe;
      self.selectedCourse = selectedCourse;
      self.selectedUnit = selectedUnit;
    },
  }));

type WordFilterStoreType = Instance<typeof WordFilterStoreModel>;
export type WordFilterStore = WordFilterStoreType;
type WordFilterStoreSnapshotType = SnapshotOut<typeof WordFilterStoreModel>;
export type WordFilterStoreSnapshot = WordFilterStoreSnapshotType;
