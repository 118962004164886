import { useTranslation } from "react-i18next";

import { Text } from "../Text";

import styles from "./ReportContentHeading.module.scss";

interface ReportContentHeadingProps {
  title: string;
}

export const ReportContentHeading = function ReportContentHeading(
  props: ReportContentHeadingProps
) {
  const { title } = props;

  const { t } = useTranslation();

  return (
    <div className={styles["unit-content-right-top"]}>
      <div className={styles["unit-content-right-title"]}>
        <Text variant="text-2-semibold">{title}</Text>
      </div>

      <div>
        <div className={styles["unit-content-right-middle"]}>
          <div>
            <Text variant="text-3-semibold">
              {t("TeacherReport:HeaderNormal")}
            </Text>
          </div>
          <div>
            <Text variant="text-3-semibold">
              {t("TeacherReport:HeaderTest")}
            </Text>
          </div>
        </div>

        <div className={styles["unit-content-right-bottom"]}>
          <div>
            <Text variant="text-4-semibold" color="accsent-1wd">
              {t("TeacherReport:HeaderCompleted")}
            </Text>
          </div>
          <div>
            <Text variant="text-4-semibold" color="accsent-2s">
              {t("TeacherReport:HeaderResult")}
            </Text>
          </div>

          <div>
            <Text variant="text-4-semibold" color="accsent-1wd">
              {t("TeacherReport:HeaderCompleted")}
            </Text>
          </div>
          <div>
            <Text variant="text-4-semibold" color="accsent-2s">
              {t("TeacherReport:HeaderResult")}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
