import {
  CourseResponse,
  transformCourseResponse,
  CoursesResponse,
  transformCoursesResponse,
} from "./types";
import { api } from "../api";

export const getCourses = () => {
  return api
    .get<CoursesResponse>("/api/v2/courses")
    .then((res) => transformCoursesResponse(res.data));
};

export const getCourse = (courseId: string) => {
  return api
    .get<CourseResponse>(`/api/v3/courses/${courseId}`)
    .then((res) => transformCourseResponse(res.data));
};
