import { forwardRef, ReactNode, useMemo } from "react";

import cn from "classnames";

import { StandardInput, StandardInputProps } from "./StandardInput";
import { BasicInputProps } from "../BasicInput";

import styles from "./Input.module.scss";

export type InputProps = {
  containerClassName?: string;
  wrapperClassName?: string;
  type: InputType;
} & BasicInputProps;

type InputType = "name" | "email" | "text" | "number";

type ConditionalProps = StandardInputProps;

export const Input = forwardRef<
  HTMLInputElement,
  InputProps & ConditionalProps
>(function Input(props, ref) {
  const { containerClassName, type } = props;

  const containerClasses = cn(styles.container, containerClassName);

  const InputView: Record<InputType, ReactNode> = useMemo(() => {
    const inputProps = {
      ref,
      ...props,
    };

    return {
      name: <StandardInput {...(inputProps as StandardInputProps)} />,
      email: <StandardInput {...(inputProps as StandardInputProps)} />,
      text: <StandardInput {...(inputProps as StandardInputProps)} />,
      number: <StandardInput {...(inputProps as StandardInputProps)} />,
    };
  }, [ref, props]);

  const Element = InputView[type as InputType];

  return <div className={containerClasses}>{Element}</div>;
});
