const ru = {
  TeacherReport: {
    Title: "Прогресс по модулю",
    Students: "Студенты",
    Total: "Всего",
    HeaderTest: "Тест",
    HeaderNormal: "Обучение",
    HeaderCompleted: "Выполнено",
    HeaderResult: "Результат",
    Blocks: "Блоки",
    StudentSelect: "Выберите студента",
    DeleteReport: "Вы уверены?",
    DeleteTooltip: "Удалить",
    DownloadTooltip: "Скачать",
  },
  BlockTypes: {
    Vocabulary: "Слова",
    Video: "Видео",
    Grammar: "Грамматика",
    Reading: "Чтение",
    Writting: "Письмо",
    Speaking: "Говорение",
    Test: "Тест",
  },
  Loading: {
    Error: "Ошибка загрузки, попробуйте обновить страницу",
  },
  SteppedProgress: {
    Completed: "Завершено",
  },
};

export default ru;
