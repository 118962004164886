import { ComponentProps, forwardRef } from "react";

import cn from "classnames";

import { Icon, IconType } from "../Icon";
import { Text } from "../Text";

import styles from "./BasicInput.module.scss";

type NativeInputProps = Omit<ComponentProps<"input">, "ref">;

type InputProps = {
  error?: string;
  onRightIconClick?: () => void;
  variant?: "primary" | "secondary";
} & NativeInputProps;

export type BasicInputProps = {
  containerClassName?: string;
  wrapperClassName?: string;
  errorClassName?: string;
  leftIcon?: IconType;
  rightIcon?: IconType;
} & InputProps;

export const BasicInput = forwardRef<HTMLInputElement, BasicInputProps>(
  function BasicInput(props, ref) {
    const {
      containerClassName,
      wrapperClassName,
      errorClassName,
      leftIcon,
      rightIcon,
      error,
      disabled,
      type = "text",
      variant,
      onRightIconClick,
      ...inputProps
    } = props;

    const isError = !!error;

    const containerClasses = cn(styles.container, containerClassName);

    const inputClasses = cn(styles["input"], {
      [styles["input-error"]]: isError,
      [styles["input-disabled"]]: disabled,
    });

    const iconClasses = cn(styles["icon"], {
      [styles["icon-error"]]: isError,
      [styles["icon-disabled"]]: disabled,
    });

    return (
      <div className={containerClasses}>
        <label
          className={cn(
            styles["wrapper"],
            styles[`wrapper-${variant}`],
            wrapperClassName
          )}
        >
          {leftIcon && (
            <Icon containerClassName={iconClasses} icon={leftIcon} />
          )}
          <div className={styles["input-wrapper"]}>
            <input
              ref={ref}
              className={inputClasses}
              type={type}
              disabled={disabled}
              {...inputProps}
            />
          </div>
          {rightIcon && (
            <Icon
              containerClassName={iconClasses}
              icon={rightIcon}
              onClick={onRightIconClick}
            />
          )}
        </label>
        {error && (
          <div className={cn(styles["error-wrapper"], errorClassName)}>
            <Icon icon="input-error" />
            <Text
              containerClassName={styles["error-text"]}
              variant="text-3-medium"
              color="accsent-wd"
            >
              {error}
            </Text>
          </div>
        )}
      </div>
    );
  }
);
