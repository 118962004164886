import { useGetCourse } from "@packages/data/courses";
import { Report } from "@packages/data/reports";

import { Loading } from "../Loading";
import { ReportTable } from "../ReportTable";

interface ReportCourseProps {
  courseId: string;
  records: Report["data"][number]["records"];
  onUnitContentClick: (
    record: Report["data"][number]["records"][number],
    unitId: string,
    variant: "test" | "normal"
  ) => void;
}

export const ReportCourse = function ReportCourse(props: ReportCourseProps) {
  const { courseId, records, onUnitContentClick } = props;

  const { data: course, isLoading, isError } = useGetCourse(courseId);

  return (
    <Loading loading={isLoading} error={isError}>
      <ReportTable
        records={records}
        title={course?.name}
        contentBlocks={course?.units ?? []}
        getTotalPassing={(record) => record.course}
        getContentBlockPassings={(record, id) => record.unitPassings[id]}
        onContentClick={onUnitContentClick}
      />
    </Loading>
  );
};
