import { useCallback } from "react";

import cn from "classnames";

import { Text } from "../Text";

import styles from "./SelectItem.module.scss";

export interface SelectItemProps {
  item: SelectItemData;
  selected?: boolean;
  onClick?: (_: string) => void;
  variant: "primary" | "secondary";
}

export type SelectItemData = {
  value: string;
  label: string;
};

export const SelectItem = function SelectItem(props: SelectItemProps) {
  const { item, selected, onClick, variant } = props;

  const { value, label } = item;

  const handleClick = useCallback(() => {
    onClick?.(value);
  }, [value, onClick]);

  const containerClasses = cn(styles[`container-${variant}`], {
    [styles[`selected-${variant}`]]: selected,
  });

  return (
    <div className={containerClasses} onClick={handleClick}>
      <Text variant="text-3-medium">{label}</Text>
    </div>
  );
};
