import { BlockResponse, transformResponse } from "./types";
import { api } from "../api";

// Почему по этому запросу приходит не информация о блоке а все упражнения блока, неизвестно
export const getBlock = (blockId: string) => {
  return api
    .get<BlockResponse>(`/api/v3/blocks/${blockId}`)
    .then((res) => transformResponse(res.data));
};

export * from "./queries";
export * from "./types";
