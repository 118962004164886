import { ReactNode } from "react";

import cn from "classnames";

import { Text } from "../Text";

import styles from "./Tooltip.module.scss";

interface TooltipProps {
  containerClassName?: string;
  children?: ReactNode;
  title?: ReactNode | string;
}

export const Tooltip = function Tooltip(props: TooltipProps) {
  const { containerClassName, children, title } = props;

  const isStringTitle = typeof title === "string";

  const containerClasses = cn(styles.container, containerClassName);

  const tooltipClasses = cn(styles.tooltip);

  const TooltipText = isStringTitle ? (
    <Text variant="text-3-regular">{title}</Text>
  ) : (
    title
  );

  return (
    <div className={containerClasses}>
      {children}
      {title && <div className={tooltipClasses}>{TooltipText}</div>}
    </div>
  );
};
