import { useQuery } from "@tanstack/react-query";

import { getBlock } from "./index";

export const useGetBlock = (blockId?: string) => {
  return useQuery({
    queryKey: ["blockDetails", blockId],
    queryFn: () => getBlock(blockId ?? ""),
    enabled: !!blockId,
  });
};
