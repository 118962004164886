import { Report } from "./types";

export const downloadXlsx = (
  report: Pick<Report, "name" | "xlsxPath">,
  form: "short" | "long" = "long"
) => {
  let url = report.xlsxPath;

  if (form === "short") {
    url += "?type=short";
  }

  const link = document.createElement("a");
  link.download = report.name + ".xlsx";
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
