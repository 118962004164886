import { useMemo } from "react";

import { Report } from "@packages/data/reports";

import { ReportModalData } from "../../types";
import { BlockWrapper } from "../BlockWrapper";
import { ReportCourse } from "../ReportCourse/ReportCourse";
import { Text } from "../Text";

import styles from "../../page.module.scss";

interface ReportGroupProps {
  data: Report["data"][number];
  studentId?: string;
  handleUnitContentClick: (data: ReportModalData) => void;
}

export const ReportGroup = function ReportGroup(props: ReportGroupProps) {
  const { data, handleUnitContentClick, studentId } = props;

  const filteredCourses = useMemo(() => {
    const getFilteredRecords = (
      records: Report["data"][number]["records"],
      courseId: string
    ) => {
      return records.filter((r) => {
        const correctStudent = studentId ? r.studentId === studentId : true;
        const correctCourse = r.courseId === courseId;
        return correctCourse && correctStudent;
      });
    };

    return data.learningCourses
      .map((courseId) => {
        return {
          courseId,
          reports: getFilteredRecords(data.records, courseId),
        };
      })
      .filter(({ reports }) => reports.length > 0);
  }, [data.learningCourses, data.records, studentId]);

  if (filteredCourses.length === 0) return null;

  return (
    <BlockWrapper>
      <Text variant="text-1-bold">{data.groupName}</Text>

      <div className={styles.courses}>
        {filteredCourses.map(({ courseId, reports }) => {
          return (
            <ReportCourse
              key={courseId}
              courseId={courseId}
              records={reports}
              onUnitContentClick={(record, unitId, variant) =>
                handleUnitContentClick({
                  courseId,
                  unitId,
                  variant,
                  record,
                })
              }
            />
          );
        })}
      </div>
    </BlockWrapper>
  );
};
