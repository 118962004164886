const blockTypeMap = {
  "6": "Video",
  "7": "Vocabulary",
  "1": "Grammar",
  "3": "Reading",
  "8": "Writing",
  "4": "Speaking",
  "5": "Test",
} as const;

export type BlockType = (typeof blockTypeMap)[keyof typeof blockTypeMap];

export interface CoursesResponse {
  courses: {
    id: string;
    translationsAttributes: { locale: string; name: string }[];
  }[];
}

export const transformCoursesResponse = (response: CoursesResponse) => {
  return response.courses.map((course) => ({
    ...course,
    translations: course.translationsAttributes,
  }));
};

export interface CourseResponse {
  course: {
    id: string;
    name: string;

    units: {
      id: string;
      name: string;
      order: number;

      blocks: {
        id: string;
        name: string;
        order: number;
        blockTypeId: keyof typeof blockTypeMap;
      }[];
    }[];
  };
}

export const transformCourseResponse = (response: CourseResponse) => {
  return {
    ...response.course,
    units: response.course.units
      .sort((a, b) => a.order - b.order)
      .map((unit) => ({
        ...unit,
        name: unit.name.replace(/(Unit|Юнит)\s[0-9]+\./i, ""),
        blocks: unit.blocks
          .sort((a, b) => a.order - b.order)
          .map((block) => ({
            ...block,
            type: blockTypeMap[block.blockTypeId],
          })),
      })),
  };
};

export type Course = ReturnType<typeof transformCourseResponse>;
export type Block = Course["units"][number]["blocks"][number];
