import { useMemo, useState, useTransition } from "react";

import uniqBy from "lodash/uniqBy";
import { useTranslation } from "react-i18next";

import {
  useGetReport,
  downloadXlsx,
  useDeleteReports,
} from "@packages/data/reports";

import { BlockWrapper } from "./components/BlockWrapper";
import { Icon } from "./components/Icon";
import { Loading } from "./components/Loading";
import { ReportDetails } from "./components/ReportDetails";
import { ReportGroup } from "./components/ReportGroup/ReportGroup";
import { Select } from "./components/Select/Select";
import { Text } from "./components/Text";
import { Tooltip } from "./components/Tooltip";
import { withProviders } from "./components/withProviders";
import { ReportModalData } from "./types";

import styles from "./page.module.scss";
// import { ROUTES } from "./constants";

interface TeacherReportProps {
  id: string;
}

export const TeacherReport = withProviders((props: TeacherReportProps) => {
  const { id } = props;

  const { t } = useTranslation();

  const { data: report, isLoading, isError } = useGetReport(id);
  const { mutate: removeItems } = useDeleteReports();

  const [modalData, setModalData] = useState<ReportModalData | null>(null);
  const [studentId, setStudentId] = useState<string>();

  const [, startTransition] = useTransition();

  const studentsToSelect = useMemo(() => {
    return uniqBy(
      report?.data.flatMap((item) => item.records),
      "studentId"
    ).map((record) => ({
      value: record.studentId,
      label: record.studentName,
    }));
  }, [report]);

  const handleReportDownload = () => {
    if (!report) return;

    downloadXlsx(report, "short");
  };

  const handleReportTrash = () => {
    if (!report) return;

    const isDelete = window.confirm(t("TeacherReport:DeleteReport") ?? "");
    if (!isDelete) return;

    removeItems({ ids: [report.id], type: report.type });
  };

  const handleUnitContentClick = (data: ReportModalData) => {
    setModalData(data);
  };

  const handleStudentChange = (value: string | undefined) => {
    // Фильтрация занимает много времени
    startTransition(() => {
      setStudentId(value);
    });
  };

  return (
    <Loading
      containerClassName={styles.loading}
      loading={isLoading}
      error={isError}
    >
      <BlockWrapper>
        <div className={styles.header}>
          <div className={styles["header-top"]}>
            <div className={styles.title}>
              {/* <Link href={ROUTES.REPORTS}>
                  <Icon icon="arrow-back" />
                </Link> */}

              <Text variant="h1-desctop">{t("TeacherReport:Title")}</Text>
            </div>

            <div className={styles.actions}>
              <Tooltip title={t("TeacherReport:DownloadTooltip")}>
                <Icon
                  width={25}
                  height={25}
                  icon="download"
                  color="#5783F1"
                  onClick={handleReportDownload}
                />
              </Tooltip>
              <Tooltip title={t("TeacherReport:DeleteTooltip")}>
                <Icon
                  width={25}
                  height={25}
                  icon="trash"
                  color="#FF2E00"
                  onClick={handleReportTrash}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </BlockWrapper>

      <Select
        containerClassName={styles["students-filter"]}
        variant="primary"
        items={studentsToSelect}
        value={studentId}
        onChange={handleStudentChange}
        placeholder={t("TeacherReport:StudentSelect") ?? ""}
        searchable
        clearable
      />

      {report?.data.map((item) => (
        <ReportGroup
          key={item.groupId}
          data={item}
          studentId={studentId}
          handleUnitContentClick={handleUnitContentClick}
        />
      ))}

      <ReportDetails
        isOpen={!!modalData}
        data={modalData}
        onClose={() => setModalData(null)}
      />
    </Loading>
  );
});
