import { useCallback, useMemo } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";

import { StepItem, BackgroundColor, FillColor } from "./StepItem";
import { Text } from "../../Text";

import styles from "./SteppedProgressBar.module.scss";

const STEPS_COUNT = 10;

export interface SteppedProgressBarProps {
  type: "stepped";
  value?: number;
  total?: number;
  subtitle?: string;
  showPercent?: boolean;
  subtitleAlign?: SubtitleAlign;
  stepFillColor?: FillColor;
  stepBackgroundColor?: BackgroundColor;
}

type SubtitleAlign = "left" | "center" | "right";

export const SteppedProgressBar = function SteppedProgressBar(
  props: SteppedProgressBarProps
) {
  const {
    subtitle,
    subtitleAlign = "center",
    stepFillColor = "purple",
    stepBackgroundColor = "gray",
    value = 0,
    total = 0,
    showPercent,
  } = props;

  const { t } = useTranslation();

  const currentProgress = (value / total) * 100;

  const subtitleClasses = cn(styles.subtitle, styles[subtitleAlign]);

  const isFilled = useCallback(
    (step: number): boolean => {
      const stepProgress = (step / STEPS_COUNT) * 100;
      return stepProgress <= currentProgress;
    },
    [currentProgress]
  );

  const Items = useMemo(() => {
    const steps = Array.from(Array(STEPS_COUNT).keys());
    return steps.map((step) => (
      <StepItem
        key={`progress-step-${step}`}
        filled={isFilled(step)}
        backgroundColor={stepBackgroundColor}
        fillColor={stepFillColor}
      />
    ));
  }, [isFilled, stepFillColor, stepBackgroundColor]);

  return (
    <>
      <div className={styles.progress}>
        {Items}
        {showPercent && (
          <Text alignment="right" variant="text-4-bold-16">
            {currentProgress.toFixed(0)} %
          </Text>
        )}
      </div>
      <Text
        variant="text-4-bold"
        color="base-d"
        containerClassName={subtitleClasses}
      >
        {subtitle} {value} / {total} {t("SteppedProgress:Completed")}
      </Text>
    </>
  );
};
