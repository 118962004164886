import { useState, useCallback, useEffect, CSSProperties, useRef } from "react";

export function useDropdown<T extends HTMLElement>() {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<T | null>(null);

  const [styles, setStyles] = useState<CSSProperties>({});

  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const { target } = event;

      if (
        container?.contains(target as Node) ||
        dropdownRef.current?.contains(target as Node)
      ) {
        return;
      }

      setIsOpen(false);
    };

    const handleWindowScroll = () => {
      setIsOpen(false);
    };

    const handleKeyup = (event: KeyboardEvent) => {
      const { code } = event;
      if (code !== "Escape") {
        return;
      }
      setIsOpen(false);
    };

    window.addEventListener("keyup", handleKeyup);
    window.addEventListener("scroll", handleWindowScroll);
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("keyup", handleKeyup);
      window.removeEventListener("scroll", handleWindowScroll);
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [container]);

  useEffect(() => {
    if (!container) return;

    const updateStyles = () => {
      if (!container) return;
      const { bottom, left, width } = container.getBoundingClientRect();

      setStyles({
        position: "fixed",
        top: `${bottom}px`,
        left: `${left}px`,
        width: `${width}px`,
      });
    };

    if (!resizeObserverRef.current) {
      resizeObserverRef.current = new ResizeObserver(updateStyles);
    }

    updateStyles();
    resizeObserverRef.current.observe(container);

    return () => {
      resizeObserverRef.current?.disconnect();
    };
  }, [container]);

  return {
    containerRef: setContainer,
    dropdownRef,
    styles,
    isOpen,
    open,
    toggle,
    close,
  };
}
