import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { Count } from "./styledComponents/Count";
import { Percent } from "./styledComponents/Percent";
import { PercentDefault } from "./styledComponents/PercentDefault";
import { ProgressActiveSteps } from "./styledComponents/ProgressActiveSteps";
import { ProgressDefault } from "./styledComponents/ProgressDefault";
import { ProgressPrimary } from "./styledComponents/ProgressPrimary";
import { ProgressSteps } from "./styledComponents/ProgressSteps";
import { Row } from "./styledComponents/Row";
import { TitleDefault } from "./styledComponents/TitleDefault";
import { TitlePrimary } from "./styledComponents/TitlePrimary";
import { TitleSteps } from "./styledComponents/TitleSteps";

export interface ProgressProps {
  value: number | undefined | null;
  type: "primary" | "default" | "steps" | "attendance";
  steps?: number | undefined;
  title?: string | null;
  colorSteps?: string;
}

type progress = {
  [key in ProgressProps["type"]]: () => JSX.Element;
};

export function Progress({
  value = 0,
  type,
  steps = 10,
  title,
  colorSteps,
}: ProgressProps): JSX.Element {
  const [percentage, setPercentage] = useState<string>("0");
  const { t } = useTranslation();

  useEffect(() => {
    if (["steps", "attendance"].includes(type) && value && steps) {
      const percent = (value * 100) / steps || 0;

      setPercentage(percent.toFixed(0));
    }
  }, [value, steps, type]);

  const getValue = (): string | number | null | undefined => {
    if (value?.toString().includes(".")) {
      return value?.toFixed(1);
    }

    return value;
  };

  const progress: progress = {
    primary: () => (
      <>
        <TitlePrimary variant="regularText">{title}</TitlePrimary>

        <Row>
          <ProgressPrimary
            sx={{
              ".MuiLinearProgress-bar": {
                backgroundColor: colorSteps || "info.main",
                borderRadius: "0.375rem",
              },
            }}
            variant="determinate"
            value={value || 0}
          />
          <Percent variant="h3" sx={{ color: colorSteps || "info.main" }}>
            {value} %
          </Percent>
        </Row>
      </>
    ),
    default: () => (
      <>
        <TitleDefault variant="h5">{title}</TitleDefault>

        <Row>
          <ProgressDefault variant="determinate" value={value || 0} />
          <PercentDefault variant="h3">{value} %</PercentDefault>
        </Row>
      </>
    ),
    steps: (): JSX.Element => {
      const items = new Array(10).fill(0);

      return (
        <>
          <TitleSteps variant="h4">{title}</TitleSteps>

          <Count variant="h5">
            {getValue()} / {steps} {t(`Progress:completed`)}
          </Count>

          <Row>
            {items.map((_, index) => {
              const id = index;
              return Math.ceil(Number(percentage)) >= (index + 1) * 10 ? (
                <ProgressActiveSteps key={id} color={colorSteps} />
              ) : (
                <ProgressSteps key={id} />
              );
            })}

            <Percent variant="h3">{percentage} %</Percent>
          </Row>
        </>
      );
    },
    attendance: (): JSX.Element => (
      <>
        <TitlePrimary variant="regularText">{title}</TitlePrimary>

        <Row>
          <ProgressPrimary
            sx={{
              ".MuiLinearProgress-bar": {
                backgroundColor: colorSteps || "info.main",
                borderRadius: "0.375rem",
              },
            }}
            variant="determinate"
            value={+percentage}
          />
          <Percent variant="h3" sx={{ color: colorSteps || "info.main" }}>
            {percentage} %
          </Percent>
        </Row>
      </>
    ),
  };

  return (
    <Box
      sx={{
        ".MuiLinearProgress-root": {
          maxWidth: "165px",
        },
      }}
    >
      {progress[type]()}
    </Box>
  );
}
