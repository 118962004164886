import { useMemo } from "react";

import cn from "classnames";

import * as Icons from "../../assets/icons";

import styles from "./Icon.module.scss";

interface IconProps {
  containerClassName?: string;
  icon: IconType;
  color?: string;
  stroke?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
  squareSize?: number;
}

type IconDefaultProps = {
  stroke?: string;
  color?: string;
  onClick?: () => void;
  width?: number;
  height?: number;
};

export type IconType =
  | "download"
  | "trash"
  | "arrow-back"
  | "input-error"
  | "input-email"
  | "input-person"
  | "stats"
  | "close-modal"
  | "arrow-down"
  | "select-up"
  | "select-down"
  | "close-filters";

export const Icon = function Icon(props: IconProps) {
  const {
    containerClassName,
    icon,
    color,
    stroke,
    onClick,
    width: iconWidth,
    height: iconHeight,
    squareSize,
  } = props;

  const containerClasses = cn(styles.container, containerClassName, {
    [styles.clickable]: !!onClick,
  });

  const width = iconWidth ?? squareSize;
  const height = iconHeight ?? squareSize;

  const defaultProps: IconDefaultProps = useMemo(
    () => ({
      stroke,
      color,
      ...(width && { width }),
      ...(height && { height }),
      // Это для работы метода contains, почему-то если target в событии это svg то ничего не работает
      // функция обработки клика все равно навешивается на контейнер
      style: {
        pointerEvents: "none",
      },
    }),
    [stroke, color, width, height]
  );

  const IconView: Record<IconType, JSX.Element> = useMemo(
    () => ({
      download: <Icons.Download {...defaultProps} />,
      trash: <Icons.Trash {...defaultProps} />,
      "arrow-back": <Icons.ArrowBack {...defaultProps} />,
      "input-error": <Icons.InputError {...defaultProps} />,
      "input-email": <Icons.InputEmail {...defaultProps} />,
      "input-person": <Icons.InputPerson {...defaultProps} />,
      stats: <Icons.Stats {...defaultProps} />,
      "close-modal": <Icons.CloseModal {...defaultProps} />,
      "arrow-down": <Icons.ArrowDown {...defaultProps} />,
      "select-up": <Icons.SelectUp {...defaultProps} />,
      "select-down": <Icons.SelectDown {...defaultProps} />,
      "close-filters": (
        <Icons.CloseFilters {...defaultProps} viewBox="0 0 24 24" />
      ),
    }),
    [defaultProps]
  );

  return (
    <div className={containerClasses} onClick={onClick}>
      {IconView[icon]}
    </div>
  );
};
