import { useCallback, useEffect, useState } from "react";

import { ThemeProvider, CssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useRoutes } from "react-router-dom";

import { defaultTheme } from "@packages/shared/themes/default";
import {
  RootStore,
  RootStoreProvider,
  setupRootStore,
} from "@packages/store/models";
import { ErrorBoundary } from "@packages/store/services/ErrorHandler/ErrorBoundary";

import { ActivityTracker } from "components/ActivityTracker";
import { PopupCookies } from "components/PopupCookies";
import { SetupPasswordRedirect } from "components/SetupPasswordRedirect";
import {
  useEntranceTestCheck,
  useInactivityReload,
  usePromocodeAndConfirmation,
} from "hooks";
import {
  unauthorizedRoutes,
  studentRoutes,
  teacherRoutes,
  hrRoutes,
  distributorRoutes,
  payrollAccountantRoutes,
  passwordSetupRouters,
  emailNotConfirmedRoutes,
} from "router/routes";

const queryClient = new QueryClient();

export const App = observer((): JSX.Element | null => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);

  const { auth, api } = rootStore ?? {};
  const { isSingIn = false, user } = auth ?? {};
  const {
    isTeacher,
    isDistributor,
    isHR,
    isCoordinator,
    isPayrollAccountant,
    isEmailConfirmed,
  } = user ?? {};

  useInactivityReload({ active: Boolean(isTeacher) });
  usePromocodeAndConfirmation(auth, api);
  useEntranceTestCheck(auth);

  const getRoute = useCallback(() => {
    if (isSingIn && isHR !== undefined) {
      if (isTeacher) {
        return teacherRoutes;
      }
      if (isHR) {
        return hrRoutes;
      }
      if (isDistributor || isCoordinator) {
        return distributorRoutes;
      }
      if (isPayrollAccountant) {
        return payrollAccountantRoutes;
      }

      if (isEmailConfirmed === false) {
        return emailNotConfirmedRoutes;
      }

      return studentRoutes;
    }
    return unauthorizedRoutes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rootStore,
    isSingIn,
    isTeacher,
    isHR,
    isDistributor,
    isPayrollAccountant,
    isEmailConfirmed,
  ]);

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    (async () => {
      setupRootStore().then(setRootStore);
    })();
  }, []);

  const routes = getRoute();
  const routing = useRoutes([passwordSetupRouters, routes]);

  if (!rootStore) return null;

  return (
    <ErrorBoundary>
      <RootStoreProvider value={rootStore}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            {routing}
            <PopupCookies />
            <ActivityTracker />
            <SetupPasswordRedirect />
          </ThemeProvider>
        </QueryClientProvider>
      </RootStoreProvider>
    </ErrorBoundary>
  );
});
