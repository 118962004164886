import { FC } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { I18nextProvider } from "react-i18next";

import i18n from "../locales/i18n";

const queryClient = new QueryClient();

export const withProviders = (Component: FC<any>) => {
  return (props: any) => {
    return (
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <Component {...props} />
        </I18nextProvider>
      </QueryClientProvider>
    );
  };
};
