/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { ApisauceInstance, create, ApiResponse } from "apisauce";
import axios, { CancelToken } from "axios";
import { ISetupCache, setupCache } from "axios-cache-adapter";
import axiosRetry from "axios-retry";
import { camelCase, snakeCase } from "change-case";
import { utcToZonedTime } from "date-fns-tz";
import { mapKeys, uniqBy, uniq, chunk, omit } from "lodash";
import Reactotron from "reactotron-react-js";

import { withRetry } from "@packages/shared/utils/withRetry";

import { ApiConfig, DEFAULT_API_CONFIG } from "./ApiConfig";
import { getGeneralApiProblem } from "./ApiProblem";
import * as Types from "./ApiTypes";
import {
  BlockApi,
  LessonCredentials,
  LessonUpdate,
  LessonUpdateData,
  TeacherCourse,
  WordVocabularySource,
  UserActivityData,
  UserVisitsData,
  GenerateDistributorReportProps,
  DistributorReportApi,
  DistributorReportFilterData,
  CreateDistributorReportData,
  CheckExerciseProps,
  GetExerciseResultDetails,
  GetExersiceResults,
  UpdateExerciseResult,
} from "./ApiTypes";
import { RegisterProps } from "../../models/AuthStore";
import { ExerciseResultCorrection } from "../../models/ExerciseResult/ExerciseResultCorrection";
import {
  ExerciseErrorType,
  ExerciseResultError,
} from "../../models/ExerciseResult/ExerciseResultError";
import {
  DateRange,
  LearningGroup,
} from "../../models/LearningGroups/LearningGroupModel";
import { UsefulLinkSnapshot } from "../../models/UsefulLink/UsefulLink";
import { transformObjectKeys } from "../../utils/basic";
import {
  convertExerciseTimeToDate,
  isLessonEditable,
  formatDateInNumberStyle,
  toStringOrNull,
  toNumberOrNull,
  filterExerciseResultErrorsByTypes,
  objectToEncodedURIParams,
  getNotificationTaskId,
} from "../../utils/helpers";
import { typecheckExercise } from "../../utils/typecheckers";

export const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Manages all requests to the API.
 */

interface UsefulLinksSnapshotWithType extends UsefulLinkSnapshot {
  __typename: string;
}

const reactotron: any = Reactotron;
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Cache
   */
  cache: ISetupCache | undefined;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    this.cache = setupCache({
      maxAge: 15 * 60 * 1000,
      clearOnStale: true,
      clearOnError: true,
      exclude: {
        query: false,
        methods: ["put", "patch", "delete", "post"],
        paths: [
          /notifications/,
          /payroll-service/,
          /passings/,
          /api\/v2\/exercise_checking_results\/index_teacher/,
          /api\/v3\/exercises\/results/,
        ],
      },
    });

    // Инстанс нужен чтобы навесить интерцепторы, например axiosRetry
    const axiosInstance = axios.create({
      adapter: this.cache.adapter,
    });
    axiosRetry(axiosInstance, { retries: 3 });

    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
      axiosInstance,
    });
    this.apisauce.addMonitor(reactotron.apisauce);
  }

  async getTeacherCourses(): Promise<any> {
    // make the api call
    const response: ApiResponse<TeacherCourse> = await this.apisauce.get(
      "/api/v3/teacher/courses"
    );
    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const resultCourses: any = transformedData.availableCourses?.map(
        (course: any) => ({
          ...course,
          __typename: "course",
          languageId: String(course.languageId),
          knowledgeLevelId: String(course.knowledgeLevelId),
          knowledgeAreaId: String(course.knowledgeAreaId),
          distributorId: String(course.distributorId),
          duration: +course.duration,
        })
      );
      return { kind: "ok", courses: resultCourses };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getLessonCredentials(id: string): Promise<any> {
    const response: ApiResponse<LessonCredentials> = await this.apisauce.get(
      `/api/v2/lessons/${id}/webinar_host_credentials`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const credentials = {
        ...transformedData,
      };
      return { kind: "ok", credentials };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getCurrentUser(): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v2/current_user"
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);

      return { kind: "ok", user: transformedData };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getUsefulLinks(token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v2/attached_files",
      {},
      { cancelToken: token }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const resultUsefulLinks: UsefulLinksSnapshotWithType[] =
        transformedData.map((link: any) => ({
          ...link,
          __typename: "usefulLink",
        }));

      return { kind: "ok", usefulLinks: resultUsefulLinks };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async registerUser(props: RegisterProps, promocode?: string) {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.post(
      "/api/v3/users",
      {
        user: mapKeys(props, (_, key) => snakeCase(key)),
        promocode,
      }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      this.apisauce.setBaseURL(`${this.config.url}`);

      return { kind: "ok" };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async loginUser(email: string, password: string): Promise<Types.LoginUser> {
    const response: ApiResponse<any> = await this.apisauce.post(
      "/oauth/token",
      {
        email,
        password,
        client_id: this.config.clientId,
        grant_type: "password",
      }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);

      this.apisauce.setBaseURL(`${this.config.url}`);

      return { kind: "ok", user: transformedData };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async loginUserByToken(token: string): Promise<Types.LoginByToken> {
    const response: ApiResponse<any> = await this.apisauce.post(
      "/api/v3/users/auth",
      {
        token,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      return { kind: "ok" };
    } catch {
      return { kind: "bad-data" };
    }
  }

  async logout(): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.delete(
      "/api/v3/users/logout"
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    if (this.cache) {
      const store = this.cache.config.store as any;
      store.store = {};
    }

    try {
      return { kind: "ok" };
    } catch {
      return { kind: "bad-data" };
    }
  }

  async changeUserPassword(password: string): Promise<Types.ChangePassword> {
    const response: ApiResponse<any> = await this.apisauce.patch(
      "/api/v3/current_user ",
      {
        password,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      return { kind: "ok" };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async restorePasswordUser(email: string): Promise<Types.RestorePassword> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.put(
      "/api/v3/users/reset_password ",
      {
        email,
      }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      return { kind: "ok" };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getTeachers(token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v3/users/teachers",
      {},
      { cancelToken: token }
    );

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const teachers = transformedData.map((teacher: any) => {
        const { firstName, lastName } = teacher;

        return {
          __typename: "teacher",
          ...teacher,
          actualId: teacher.id,
          firstName,
          lastName,
        };
      });
      return { kind: "ok", teachers };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getCoursePassings(token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v2/course_passings",
      undefined,
      { cancelToken: token }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const passings = transformedData.coursePassings?.map((passing: any) => ({
        ...passing,
        __typename: "passing",
        id: String(passing.content?.id),
        course: passing.content?.id ? String(passing.content?.id) : null,
        knowledgeLevel: {
          ...passing.knowledgeLevel,
          __typename: "knowledgeLevel",
          translationsAttributes:
            passing.knowledgeLevel.translationsAttributes?.map(
              (translation: any) => ({
                ...passing,
                shortName: translation.shortName || null,
                description: translation.description || null,
              })
            ),
        },
        markPercent: passing.processingPercent,
        markTestPercent: passing.processingTestPercent,
        passingPercent: passing.progressPercent,
        passingTestPercent: passing.progressTestPercent,
      }));

      return { kind: "ok", passings };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getCourses(token?: CancelToken): Promise<Types.GetCourses> {
    const retryDelay = 1000;

    while (true) {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/api/v2/courses",
        {},
        { cancelToken: token }
      );

      if (response.status === 500) {
        console.log(
          `Request failed with status 500. Retrying in ${retryDelay}ms...`
        );
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        continue;
      }

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      try {
        const { data } = response;
        const transformedData = transformObjectKeys(data);
        const courses = transformedData.courses?.map((course: any) => ({
          ...course,
          __typename: "course",
          markPercent: Number(course.markPercent),
          passingPercent: Number(course.passingPercent),
          picture: course.picture?.contentUrl || null,
          courseUnits: course.courseUnits.map((unit: any) => ({
            ...unit,
            __typename: "unit",
          })),
          knowledgeLevel: {
            ...course.knowledgeLevel,
            __typename: "knowledgeLevel",
            translationsAttributes:
              course.knowledgeLevel.translationsAttributes?.map(
                (translation: any) => ({
                  ...translation,
                  shortName: translation.shortName || null,
                  description: translation.description || null,
                })
              ),
          },
          translationsAttributes: course.translationsAttributes?.map(
            (translation: any) => ({
              ...translation,
              shortName: translation.shortName || null,
              description: translation.description || null,
            })
          ),
          contentImages: course.contentImages?.map((image: any) => ({
            __notDeflate: true,
            ...image,
          })),
          learningCourseLinks: course.learningCourseLinks?.map((link: any) => ({
            __notDeflate: true,
            ...link,
          })),
        }));

        return { kind: "ok", courses };
      } catch (error) {
        return { kind: "bad-data", message: error };
      }
    }
  }

  async getWordFilter(token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/user-settings-service/api/v1/keys",
      {},
      { cancelToken: token }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;

      const filter = (data as any[]).reduce(
        (result, item) => {
          const { key = "", value = "" } = item;
          return {
            ...result,
            [camelCase(key)]: value,
          };
        },
        { __typename: "wordFilter" }
      );

      return { kind: "ok", filter };
    } catch (error) {
      console.log(error);
      return {
        kind: "bad-data",
        message: { getLearningGroups: error },
      };
    }
  }

  async rememberWordFilterParam(
    key: string,
    value: string | boolean
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/user-settings-service/api/v1/keys/${key}?value=${value}`
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;

      return { kind: "ok", data };
    } catch (error) {
      console.log(error);
      return {
        kind: "bad-data",
        message: { getLearningGroups: error },
      };
    }
  }

  async getClassGroupsWithLessons(
    params: Types.GetClassGroupsWithLessonsParams,
    token?: CancelToken
  ) {
    const { classGroupIds, status, fromDate, toDate } = params;
    if (classGroupIds && classGroupIds.length === 0) {
      return {
        classGroups: [],
      };
    }
    try {
      const url = "/schedules-service/api/v1/lessons";
      const limit = 100;
      const queryParams = {
        limit,
        "filter[status]": status && snakeCase(status),
        ["filter[class_group_ids]"]: classGroupIds,
        ["filter[from_date]"]: fromDate && formatDateInNumberStyle(fromDate),
        ["filter[to_date]"]: toDate && formatDateInNumberStyle(toDate),
      };

      const firstLessons: ApiResponse<any> = await this.apisauce.get(
        url,
        queryParams,
        { cancelToken: token }
      );

      const { count } = firstLessons.data?.meta ?? {};

      const offsets = new Array(Math.floor(count / limit))
        .fill(0)
        .map((_, index) => (index + 1) * limit);

      const lessonsResponses: ApiResponse<any>[] = await Promise.all(
        offsets.map(async (offset: number) => {
          return await this.apisauce.get(
            url,
            {
              ...queryParams,
              offset,
            },
            { cancelToken: token }
          );
        })
      );

      const data = transformObjectKeys(
        [firstLessons, ...lessonsResponses].reduce((result: any, response) => {
          const { data } = response;
          return {
            class_groups: [
              ...data.class_groups,
              ...(result.class_groups ?? []),
            ],
            class_schedules: [
              ...data.class_schedules,
              ...(result.class_schedules ?? []),
            ],
            lessons: [...data.lessons, ...(result.lessons ?? [])],
          };
        }, {})
      );

      const classGroupDuplicates = new Set();
      const classGroupsSource: any[] = [];
      data.classGroups.forEach((classGroup: any) => {
        if (classGroupDuplicates.has(classGroup.id)) {
          return;
        }
        classGroupDuplicates.add(classGroup.id);
        classGroupsSource.push(classGroup);
      });

      const duplicatedSchedules = new Set();
      const schedulesSource: Record<string, any[]> = {};
      data.classSchedules.forEach((schedule: any) => {
        if (!schedulesSource[schedule.classGroupId]) {
          schedulesSource[schedule.classGroupId] = [];
        }
        if (duplicatedSchedules.has(schedule.id)) {
          return;
        }
        duplicatedSchedules.add(schedule.id);
        schedulesSource[schedule.classGroupId].push(schedule);
      });

      const lessonsSource: Record<string, any[]> = {};
      data.lessons.forEach((lesson: any) => {
        if (!lessonsSource[lesson.classScheduleId]) {
          lessonsSource[lesson.classScheduleId] = [];
        }
        lessonsSource[lesson.classScheduleId].push(lesson);
      });

      const classGroups: any[] = classGroupsSource.map((classGroup: any) => ({
        ...classGroup,
        __typename: "classGroup",
        lessonsPerUnit: +classGroup.lessonsPerUnit,
        classSchedules: schedulesSource[classGroup.id].map((schedule: any) => ({
          ...schedule,
          __typename: "classSchedule",
          classGroup: String(classGroup.id),
          creativeExerciseCheck: String(schedule.creativeExerciseCheck),
          teachersIds: schedule.teacherIds?.map(String),
          lessons: lessonsSource[schedule.id].map((lesson: any) => ({
            ...lesson,
            __typename: "lesson",
            startAtLocal: utcToZonedTime(
              lesson.startAt.split("+")[0],
              currentTimeZone
            ),
            endAtLocal: utcToZonedTime(
              lesson.endAt.split("+")[0],
              currentTimeZone
            ),
            unit: lesson.unitIds?.[0]
              ? String(lesson?.unitIds?.[0])
              : undefined,
            teacherId: toStringOrNull(lesson.teacherId),
            status: camelCase(lesson.status || ""),
            comment: String(lesson.comment ?? ""),
            webinarRecordLink: String(lesson.webinarRecordLink ?? ""),
            webinarRoomLink: String(
              lesson.webinarRoomLink ?? schedule.webinarRoomLink ?? ""
            ),
            attendance: lesson.attendance?.map((attendance: any) => ({
              ...attendance,
              studentId: +attendance.studentId,
            })),
            numberOrCode: "1", //needed
            type: classGroup.type,
          })),
        })),
      }));

      return { kind: "ok", classGroups };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getLearningGroups(
    params: Types.GetLearningGroupsParams,
    token?: CancelToken
  ): Promise<Types.GetLearningGroups> {
    try {
      const retryDelay = 1000;
      while (true) {
        const {
          ids,
          classGroupIds,
          prefetchedClassGroups,
          company,
          name,
          teacher,
          unlicensed,
          includeLessons,
          page,
          limit,
          sortBy,
          status,
          studentIdToCutByExcludeDate,
          withoutTeachers,
          aboveTwoLessonsEarlyCanceledBySchoolInLastMonth,
          classGroupsFromDate,
          classGroupsToDate,

          // Костыль
          // В запросах курсов приходят доролнительные параметры внутри translationsAttributes
          // и если тут их не убрать тут то получается race condition
          // Но иногда запрос этих курсов не нужен, поэтому
          // приходится пользоваться translationsAttributes из этого запроса
          omitCoursesTranslations = true,
        } = params;

        const queryParams = {
          ...(ids && {
            ids,
          }),
          ...(company && {
            "filter[customer_company_id]": company,
          }),
          ...(teacher && {
            "filter[teacher_id]": teacher,
          }),
          ...(name && {
            "filter[name]": name,
          }),
          ...(sortBy && {
            sort: sortBy,
          }),
          ...(status && {
            status,
          }),
          ...(page &&
            limit && {
              offset: (page - 1) * limit,
              limit,
            }),
          ...(typeof unlicensed === "undefined"
            ? {}
            : { "filter[unlicensed]": unlicensed }),
          "filter[class_group_ids]": classGroupIds,
          "filter[without_teachers]": withoutTeachers,
          ...(aboveTwoLessonsEarlyCanceledBySchoolInLastMonth && {
            "filter[by_lessons]":
              "above_two_lessons_early_canceled_by_school_in_last_month",
          }),
        };

        const response: ApiResponse<any> = await this.apisauce.get(
          "/api/v2/learning_groups",
          queryParams,
          { cancelToken: token }
        );

        if (response.status === 500) {
          console.log(
            `Request failed with status 500. Retrying in ${retryDelay}ms...`
          );
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          continue;
        }

        if (!response.ok) {
          const problem = getGeneralApiProblem(response);
          if (problem) {
            return problem;
          }
        }

        const { data } = response;

        const transformedData = transformObjectKeys(data);
        const learningGroups: LearningGroup[] = transformedData.map(
          (group: any) => ({
            ...group,
            __typename: "learningGroup",
            status: camelCase(group.status || ""),
            languageId: group?.language?.id,
            customerCompany: group.customerCompany && {
              ...group.customerCompany,
              __typename: "customerCompany",
            },
            teachers: group.teachers?.map((teacher: any) => ({
              ...teacher,
              __typename: "teacher",
              id: `${teacher.id}${group.id}`,
              actualId: teacher.id,
              learningGroup: group.id,
            })),
            students: group.students?.map((student: any) => ({
              ...student,
              __notDeflate: true,
              id: `${student.id}${group.id}`,
              studentId: student.id,
              learningGroup: group.id,
              greetedAt: student.greetedAt && new Date(student.greetedAt),
              includedAt: student.includedAt && new Date(student.includedAt),
              excludedAt: student.excludedAt && new Date(student.excludedAt),
            })),
            courses: group.learningCourses?.map((course: any) => {
              const filteredCourse = omitCoursesTranslations
                ? omit(course, "translationsAttributes")
                : course;

              return {
                ...filteredCourse,
                __typename: "course",
                startDate: course.startDate,
                lessonsPerUnit: +course.lessonsPerUnit,
                duration: +course.duration,
              };
            }),
            relatedClassGroupIds: group.learningCourses?.flatMap(
              (course: any) => course.classGroupIds
            ),
          })
        );

        if (includeLessons) {
          let classGroups = prefetchedClassGroups;

          if (!classGroups) {
            const classGroupsIdsWidthStudents = data.map((group: any) => {
              return {
                students: group?.students ?? [],
                class_group_ids: group.learning_courses.flatMap(
                  ({ class_group_ids }: any) => class_group_ids
                ),
              };
            });

            // Тут запросы обрезаются по времени когда студент был в группе
            const classGroupsResponse = await Promise.all(
              classGroupsIdsWidthStudents.map(
                ({ students, class_group_ids }: any) => {
                  const student =
                    studentIdToCutByExcludeDate &&
                    students.find(
                      (student: any) =>
                        String(student.id) ===
                        String(studentIdToCutByExcludeDate)
                    );

                  return this.getClassGroupsWithLessons(
                    {
                      classGroupIds: class_group_ids,
                      fromDate: student?.included_at
                        ? new Date(student.included_at)
                        : classGroupsFromDate,
                      toDate: student?.excluded_at
                        ? new Date(student.excluded_at)
                        : classGroupsToDate,
                    },
                    token
                  );
                }
              )
            );

            classGroups = classGroupsResponse.flatMap(
              ({ classGroups }) => classGroups
            );
          }

          // Линковка classGroups к learningGroups
          // И добавление данных о группе и курсе урокам
          learningGroups.forEach((group: any) => {
            group.classGroups = group.classGroups || [];

            group.learningCourses.forEach((course: any) => {
              const courseClassGroupsIds = course.classGroupIds ?? [];
              const courseClassGroups = (classGroups ?? [])
                .filter(
                  (classGroup: any) =>
                    classGroup && courseClassGroupsIds.includes(classGroup.id)
                )
                .map((classGroup: any) => ({
                  ...classGroup,
                  classSchedules: classGroup.classSchedules?.map(
                    (schedule: any) => ({
                      ...schedule,
                      lessons: schedule.lessons?.map((lesson: any) => ({
                        ...lesson,
                        course: String(course.id),
                        group: String(group.id),
                        editable: isLessonEditable(
                          lesson.startAtLocal,
                          new Date(group.restrictiveDate)
                        ),
                      })),
                    })
                  ),
                }));

              group.classGroups.push(...courseClassGroups);
            });
          });

          // Тут возвращаются еще и classGroups чтобы classGroups у которых нет группы все равно попали в стор
          return { kind: "ok", data: { learningGroups, classGroups } };
        }

        return { kind: "ok", data: { learningGroups } };
      }
    } catch (error) {
      console.log("Learning error", error);
      return { kind: "bad-data", message: error };
    }
  }

  async getLearningGroupsByLessonsStatus(
    { status, fromDate, toDate }: Types.GetLearningGroupsByLessonsStatusParams,
    token?: CancelToken
  ) {
    try {
      const data = await this.getClassGroupsWithLessons(
        {
          status,
          fromDate,
          toDate,
        },
        token
      );

      if (!data?.classGroups) return data;

      const classGroupIds: string[] = uniq(
        data.classGroups.map((classGroup: any) => classGroup.id)
      );

      const groupsResponses = await Promise.all(
        chunk(classGroupIds, 10).map((ids) =>
          this.getLearningGroups({
            classGroupIds: ids,
            prefetchedClassGroups: data.classGroups,
            includeLessons: true,
          })
        )
      );

      const learningGroups = groupsResponses.flatMap((res: any) => res?.data);

      return { kind: "ok", data: learningGroups };
    } catch (error) {
      console.log("Learning error", error);
      return { kind: "bad-data", message: error };
    }
  }

  async getClassGroupRates(
    params: Types.GetClassGroupRatesParams,
    token?: CancelToken
  ): Promise<Types.GetClassGroupRates> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v2/units",
      {},
      { cancelToken: token }
    );

    const url = "/payroll-service/api/v1/class_group_rates";
    const limit = 100;
    const queryParams = {
      ["filter[class_group_id]"]: params.ids,
      limit,
    };

    const firstRate: ApiResponse<any> = await this.apisauce.get(
      url,
      queryParams,
      { cancelToken: token }
    );

    const { count } = firstRate.data?.meta ?? {};

    const offsets = new Array(Math.floor(count / limit))
      .fill(0)
      .map((_, index) => (index + 1) * limit);

    const rateResponses: ApiResponse<any>[] = await Promise.all(
      offsets.map(async (offset: number) => {
        return await this.apisauce.get(
          url,
          {
            ...params,
            offset,
          },
          { cancelToken: token }
        );
      })
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const transformedData = transformObjectKeys(
        [firstRate, ...rateResponses].reduce((result: any, response) => {
          const { data } = response;
          return [...result, ...data.data];
        }, [])
      );
      const rates = transformedData.map((rate: any) => ({
        ...rate,
        __typename: "classGroupRate",
        classGroupId: String(rate.classGroupId),
        amount: Number(rate.amount),
        activeFrom: rate.activeFrom ? new Date(rate.activeFrom) : null,
        deletedAt: rate.deletedAt ? new Date(rate.deletedAt) : null,
      }));

      return { kind: "ok", rates };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getUnits(token?: CancelToken): Promise<Types.GetUnits> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v2/units",
      {},
      { cancelToken: token }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const units = transformedData.map((unit: any) => ({
        ...unit,
        __typename: "unit",
        course: unit.courseId,
        contentImages: unit.contentImages?.map((image: any) => ({
          __notDeflate: true,
          ...image,
        })),
      }));

      return { kind: "ok", units };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getCourse(courseId: string, token?: CancelToken): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.get(
      `/api/v3/courses/${courseId}`,
      {},
      { cancelToken: token }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data.course);
      const passings = {
        ...transformedData,
        __typename: "course",
        languageId: String(transformedData.languageId),
        distributorId: String(transformedData.distributorId),
        knowledgeAreaId: String(transformedData.knowledgeAreaId),
        duration: toNumberOrNull(transformedData.duration),
        units: transformedData.units?.map((unit: any) => ({
          ...unit,
          __typename: "unit",
          course: courseId,
          blocks: unit.blocks?.map((block: any) => ({
            ...block,
            __typename: "block",
          })),
          contentImages: unit.contentImages?.map((image: any) => ({
            __notDeflate: true,
            ...image,
          })),
        })),
        contentImages: transformedData.contentImages?.map((image: any) => ({
          __notDeflate: true,
          ...image,
        })),
        translationsAttributes: transformedData.translations?.map(
          (translation: any) => ({
            __notDeflate: true,
            ...translation,
          })
        ),
        learningCourseLinks: transformedData.learningCourseLinks?.map(
          (link: any) => ({
            __notDeflate: true,
            ...link,
          })
        ),
      };

      return { kind: "ok", data: passings };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getExerciseResults(
    params: GetExersiceResults,
    token?: CancelToken
  ): Promise<any> {
    const { isTeacher } = params;

    const url = isTeacher
      ? "/api/v2/exercise_checking_results/index_teacher"
      : `/api/v3/exercises/results`;

    const response: ApiResponse<any> = await this.apisauce.get(
      url,
      {},
      { cancelToken: token }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);

      const resultsData =
        transformedData.results ?? transformedData.exerciseResults;

      const results = resultsData.map((result: any) => ({
        ...result,
        __typename: "exerciseResult",
        id: String(result.id),
        type: result.exerciseType,
        name: result.courseName,
        createdAt: convertExerciseTimeToDate(
          result.createdAt ?? result.createdAtTimestamp
        ),
        checkedAt: convertExerciseTimeToDate(
          result.checkedAt ?? result.checkedAtTimestamp
        ),
        student: result.userName ?? "",
      }));

      return { kind: "ok", results };
    } catch (error) {
      console.log("Error result", error);
      return { kind: "bad-data", message: error };
    }
  }

  async getExerciseResultDetails(
    params: GetExerciseResultDetails,
    token?: CancelToken,
    invalidateCache?: boolean
  ): Promise<any> {
    const { id, isTeacher } = params;
    const action = isTeacher ? "show_teacher" : "show_student";
    const response: ApiResponse<any> = await this.apisauce.get(
      `/api/v2/exercise_checking_results/${action}/${id}`,
      {},
      {
        cancelToken: token,
        cache: {
          ignoreCache: invalidateCache,
        },
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);

      const {
        exerciseResult: result,
        exercise: execiseData,
        exerciseResultCheckHistory: checkHistory,
      } = transformedData;

      const {
        data: { questionPassings = [] },
        corrections = [],
      } = result;

      const { content } = execiseData;

      const exercise = {
        __typename: "exerciseResult",
        id: String(result.id),
        type: result.exerciseType,
        name: result.courseName,
        comment: result.comment,
        hasErrors: !!result.errors?.length,
        errors: [
          ...result.errors.map((error: any) => ({
            currentId: String(error.id),
            startPosition: +error.startPosition,
            endPosition: +error.endPosition,
            comment: error.comment,
            errorTypeId: +error.exercisesErrorTypeId,
          })),
          ...result.comments.map((comment: any) => ({
            currentId: String(comment.id),
            startPosition: +comment.startPosition,
            endPosition: +comment.endPosition,
            comment: comment.comment,
            errorTypeId: 0,
          })),
        ],
        corrections: corrections.map((correction: any) => ({
          currentId: String(correction.id),
          startPosition: +(correction.startPosition ?? 0),
          endPosition: +(correction.endPosition ?? 0),
          text: correction.text,
        })),
        courseName: result.courseName,
        taskText: content.taskText,
        checkingHistory: checkHistory.map((history: any) => ({
          checkDate: new Date(history.checkDate),
          originDate: new Date(history.originDate),
          hasErrors: history.hasErrors,
          teacher: history.teacherName,
        })),
        passings: questionPassings.map((passing: any) => {
          const { userAnswer: answer = {} } = passing;

          const {
            answer: answerText,
            recordUrl,
            spellcheckerErrors = [],
          } = answer ?? {};

          return {
            answer: answerText ?? recordUrl,
            spellcheckErrors: spellcheckerErrors.map((error: any) => ({
              text: error.text,
              startPosition: +(error.position?.[0] ?? 0),
              endPosition: +(error.position?.[1] ?? 0),
              wordCount: +error.wordCount,
            })),
          };
        }),
      };

      return { kind: "ok", exercise };
    } catch (error) {
      console.log("Error result", error);
      return { kind: "bad-data", message: error };
    }
  }

  async updateExerciseResult(
    params: UpdateExerciseResult,
    token?: CancelToken
  ): Promise<any> {
    const {
      id,
      comment,
      errors,
      removedErrors,
      corrections,
      removedCorrections,
      isTeacher,
      isSubmit,
    } = params;

    const getErrors = (errors: ExerciseResultError[]): any => {
      return errors.reduce(
        (result, error, index) => ({
          ...result,
          [index]: {
            ...(error.currentId ? { id: error.currentId } : {}),
            start_position: error.startPosition,
            end_position: error.endPosition,
            comment: error.comment,
            exercises_error_type_id: error.errorTypeId,
            question_id: 1,
            ...(removedErrors?.some((item) => item === error)
              ? {
                  _destroy: 1,
                }
              : {}),
          },
        }),
        {}
      );
    };

    const getCorrections = (corrections: ExerciseResultCorrection[]) => {
      return corrections.reduce(
        (result, error, index) => ({
          ...result,
          [index]: {
            ...(error.currentId ? { id: error.currentId } : {}),
            start_position: error.startPosition,
            end_position: error.endPosition,
            text: error.text,
            question_id: 1,
            ...(removedCorrections?.some((item) => item === error)
              ? {
                  _destroy: 1,
                }
              : {}),
          },
        }),
        {}
      );
    };

    const allErrors = [...(errors ?? []), ...(removedErrors ?? [])];
    const allCorrections = [
      ...(corrections ?? []),
      ...(removedCorrections ?? []),
    ];

    const comments = filterExerciseResultErrorsByTypes(allErrors, [
      ExerciseErrorType.COMMENT,
    ]);

    const data = objectToEncodedURIParams({
      exercise_checking_result: {
        comment,
        ...(errors
          ? {
              exercise_checking_result_errors_attributes: getErrors(
                filterExerciseResultErrorsByTypes(allErrors, [
                  ExerciseErrorType.GRAMMAR,
                  ExerciseErrorType.LEXICAL,
                ])
              ),
              ...(comments.length
                ? {
                    exercise_checking_result_comments_attributes:
                      getErrors(comments),
                  }
                : {}),
            }
          : {}),
        ...(corrections
          ? {
              exercise_checking_result_corrections_attributes:
                getCorrections(allCorrections),
            }
          : {}),
        allow_correction: 1,
      },
    });

    const action = isTeacher
      ? "exercise_checking_results"
      : "exercise_checking_results_view";

    const submitAction = isTeacher
      ? "update_with_check"
      : "update_with_correct";

    const response: ApiResponse<any> = await this.apisauce.patch(
      `/${action}/${id}${isSubmit ? `/${submitAction}` : ""}`,
      data,
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "x-requested-with": "XMLHttpRequest",
        },
        cancelToken: token,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      return { kind: "ok" };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getPassings(courseId: string, token?: CancelToken): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.get(
      `/api/v2/passings/${courseId}`,
      {},
      { cancelToken: token }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const passings = {
        ...transformedData,
        __typename: "passing",
        course: courseId,
        units: transformedData.units?.map((unit: any) => ({
          ...unit,
          __typename: "unit",
          course: courseId,
          state: camelCase(unit.state),
          blocks: unit.blocks?.map((block: any) => ({
            ...block,
            __typename: "block",
            state: camelCase(block.state),
            exercises: block.exercises?.map((exercise: any) =>
              typecheckExercise({
                ...exercise,
                __typename: "exercise",
                state: camelCase(exercise.state),
                block: block.id,
                additionalContent: exercise.additionalContent?.elements?.map(
                  (element: any) => ({
                    ...element,
                    __notDeflate: true,
                  })
                ),
                correctAnswers: exercise.correctAnswers?.map(
                  (correctAnswer: any) => ({
                    ...correctAnswer,
                    __notDeflate: true,
                    questionId: String(correctAnswer.questionId),
                    answers: correctAnswer.answers?.map((answer: any) => ({
                      ...answer,
                      diff: answer.diff?.flat(),
                    })),
                  })
                ),
                questions: exercise.questions?.map((question: any) => ({
                  ...question,
                  __notDeflate: true,
                })),
                questionsRaw: exercise.questionsRaw?.map(
                  (questionRaw: any) => ({
                    ...questionRaw,
                    __notDeflate: true,
                    id: String(questionRaw.id ?? questionRaw.q_id),
                  })
                ),
                possibleAnswers: exercise.possibleAnswers?.map(
                  (possibleAnswer: any) => ({
                    ...possibleAnswer,
                    __notDeflate: true,
                  })
                ),
                passingData: exercise.passingData?.map((item: any) => ({
                  ...item,
                  __notDeflate: true,
                  state: camelCase(item.state),
                  answers:
                    item.userAnswer?.answers?.[0] instanceof Array
                      ? item.userAnswer.answers.flat()
                      : item.userAnswer?.answers?.map((answer: any) => ({
                          ...answer,
                          state: answer.state ? camelCase(answer.state) : null,
                        })) || [item.userAnswer],
                })),
                partGroups: exercise.partGroups?.[0].map((partGroup: any) => ({
                  ...partGroup,
                  __notDeflate: true,
                })),
              })
            ),
          })),
        })),
      };

      return { kind: "ok", passings };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getWords(token?: CancelToken) {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v3/words",
      {},
      { cancelToken: token }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const sourceWords = uniqBy(transformedData.words, ({ header }) => header);

      const words = sourceWords.map((word: any) => {
        const { id, header, learnedAt, courseUnitId, pathOfSpeech } = word;

        const sameWords = sourceWords.filter((item: any) => {
          return item.header === word.header;
        });

        const wordsIdList = sameWords.map((item: any) => item.id);
        const translations = [
          ...new Set(sameWords.map((item: any) => item.translation)),
        ];
        const isLearned =
          sameWords.filter((item: any) => item.isLearned).length > 0;

        const { custom } = WordVocabularySource;

        const addedToMeWords = sameWords.find((item: any) => {
          return item.vocabularyLearningWordSourceId === custom;
        });
        const vocabularySourceId = addedToMeWords
          ? custom
          : word.vocabularyLearningWordSourceId;

        return {
          __typename: "word",
          id,
          header,
          learnedAt,
          courseUnitId,
          pathOfSpeech,
          wordsIdList,
          translations,
          isLearned,
          vocabularySourceId,
        };
      });

      return { kind: "ok", words };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getReportTests(token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v2/reports/testings",
      {},
      { cancelToken: token }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const reportTests = transformedData.map((test: any) => ({
        ...test,
        groupId: test.learningGroupId,
        __typename: "reportTest",
      }));

      return { kind: "ok", reportTests };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async markWorkAsLearned(id: string) {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/api/v3/words/${id}/learned`
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.log("Netowk error", error);
      return { kind: "bad-data", message: error };
    }
  }

  async getSummary(
    dateRange: DateRange,
    token?: CancelToken
  ): Promise<Types.GetSummary> {
    const { startDate, endDate } = dateRange;
    const params = {
      ...(startDate ? { from: formatDateInNumberStyle(startDate) } : {}),
      ...(endDate ? { to: formatDateInNumberStyle(endDate) } : {}),
    };
    const response: ApiResponse<any> = await this.apisauce.get(
      "schedules-service/api/v1/lessons/hours_summary_report",
      params,
      { cancelToken: token }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data.data);
      const items = [
        ...Object.entries(transformedData.lessonsByStatus),
        ["compensation", transformedData.compensation],
      ];
      const summary = items
        .filter(([status]) => status !== "planned")
        .map(([status, value]) => {
          const { count, hours } = value as any;
          return {
            __typename: "summary",
            count,
            hours,
            status,
          };
        });
      return { kind: "ok", summary };
    } catch (error) {
      console.log("Error is", error);
      return { kind: "bad-data", message: error };
    }
  }

  async getWordTranslation(
    word: string,
    direction: string
  ): Promise<Types.GetWordTranslation> {
    try {
      const data = {
        text: word,
        direction,
      };
      const response: ApiResponse<any> = await this.apisauce.post(
        `/api/v2/translations/dict_article`,
        data
      );

      const checkWordMatch = (definition: any) => {
        return definition.text === word.toLowerCase();
      };

      const getTranslations = (definition: any) => {
        return definition.tr
          .map((translation: any) => translation.text)
          .join(", ");
      };

      const formatDefinition = (definition: any) => {
        return {
          translations: definition.tr.map((translation: any) =>
            [
              translation.text,
              ...(translation.syn || [])
                .slice(0, 1)
                .map(({ text }: { text: any }) => text),
            ].join(", ")
          ),
          partOfSpeech: definition.pos,
        };
      };

      const definitions = response.data.def;
      const filteredDefinitions = definitions.filter(checkWordMatch);

      const transcription = filteredDefinitions[0]?.ts || "";
      const translations = filteredDefinitions.map(getTranslations);

      const formattedDefinitions = filteredDefinitions.map(formatDefinition);

      const wordTranslation: Types.WordTranslation = {
        dictionaryWord: {
          word,
          translations,
        },
        transcription,
        definitions: formattedDefinitions,
      };

      return { kind: "ok", data: wordTranslation };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getUnitsByCourse(courseId: string, token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/api/v2/courses/${courseId}/units`,
      {},
      { cancelToken: token }
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const units = transformedData.map((unit: any) => ({
        ...unit,
        __typename: "unit",
        course: unit.courseId,
        contentImages: unit.contentImages?.map((image: any) => ({
          __notDeflate: true,
          ...image,
        })),
      }));

      return { kind: "ok", data: units };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getUnit(courseId: string, unitId: string): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.get(
      `/api/v2/courses/${courseId}/units/${unitId} `
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const unit = {
        ...transformedData,
        __typename: "unit",
        course: String(transformedData.courseId),
        picture: transformedData?.picture?.fileExistentUrl,
        translationsAttributes: transformedData.translationsAttributes.map(
          (translation: any) => ({
            ...translation,
            shortName: translation.shortName || null,
            description: translation.description || null,
          })
        ),
        contentImages: transformedData.contentImages?.map((image: any) => ({
          __notDeflate: true,
          ...image,
        })),
      };

      return { kind: "ok", unit };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getTranslatedWords(unitId: string, token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v4/words",
      {
        unit_id: unitId,
      },
      { cancelToken: token }
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const { data: items } = transformObjectKeys(data);

      const words = items.map((item: any) => {
        const {
          id,
          word,
          languageId,
          pictureManual,
          wordTranslations,
          transcription,
          audioManual,
        } = item;

        const translations = wordTranslations.map(
          ({ word, languageId }: any) => ({
            translation: word,
            langId: languageId,
          })
        );

        return {
          __typename: "translatedWord",
          id,
          word,
          langId: languageId,
          pictureUrl: pictureManual,
          unitId,
          translations,
          transcription,
          audioManual,
        };
      });

      return { kind: "ok", words };
    } catch (error) {
      console.log("Fetch error", error);
      return { kind: "bad-data", message: error };
    }
  }

  async updateLesson(lessonId: string, data: LessonUpdateData): Promise<any> {
    try {
      const response: ApiResponse<LessonUpdate> = await this.apisauce.put(
        `/api/v2/lessons/${lessonId}`,
        {
          ...data,
          status: snakeCase(data.status ?? ""),
        }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { kind: "ok", id: response.data?.id };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getHRStatistics(token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v3/hr/statistics",
      {},
      { cancelToken: token }
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      return {
        kind: "ok",
        data: {
          ...transformedData,
        },
      };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async generateDistributorReport({
    dateFrom,
    dateTo,
    type = "Reports::Lessons",
    name = "Reports::Lessons",
  }: GenerateDistributorReportProps): Promise<any> {
    const response: ApiResponse<DistributorReportApi> =
      await this.apisauce.post("/api/v3/reports", {
        date_from: dateFrom,
        date_to: dateTo,
        type,
        name,
      });
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      if (!data) throw new Error("No data");
      const transformedData = transformObjectKeys(data);
      return {
        kind: "ok",
        data: {
          __typename: "distributorReport",
          ...transformedData,
        },
      };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getDistributors(token?: CancelToken): Promise<any> {
    const response: ApiResponse<DistributorReportApi> = await this.apisauce.get(
      `/api/v3/distributors`,
      {},
      { cancelToken: token }
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      if (!data) throw new Error("No data");
      const transformedData = transformObjectKeys(data.data);
      return {
        kind: "ok",
        distributors: transformedData,
      };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async sendDistributorReportMails(ids: string[]): Promise<any> {
    const response: ApiResponse<DistributorReportApi> =
      await this.apisauce.post(`/api/v3/reports/to_email`, { ids });
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      if (!data) throw new Error("No data");
      return {
        kind: "ok",
      };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async removeDistributorReports(ids: string[]): Promise<any> {
    const response: ApiResponse<DistributorReportApi> =
      await this.apisauce.delete(`/api/v3/reports/`, { ids });
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      if (!data) throw new Error("No data");
      return {
        kind: "ok",
      };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async createDistributorReport(
    data: CreateDistributorReportData
  ): Promise<any> {
    const { title: name, type, from: date_from, to: date_to, groups } = data;
    const response: ApiResponse<any> = await this.apisauce.post(
      "/api/v3/reports/",
      {
        name,
        type,
        date_from,
        date_to,
        parameters: {
          groups,
        },
      }
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      if (!data) throw new Error("No data");

      const transformedData = transformObjectKeys(data);

      return {
        kind: "ok",
        data: {
          __typename: "distributorReport",
          ...transformedData,
          createdAt: utcToZonedTime(
            transformedData.createdAt.split("+")[0],
            currentTimeZone
          ),
        },
      };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getDistributorReportByFilter(
    { type, offset, limit }: DistributorReportFilterData,
    token?: CancelToken
  ): Promise<any> {
    const response: ApiResponse<DistributorReportApi[]> =
      await this.apisauce.get(
        `/api/v3/reports`,
        {
          "filter[type]": type,
          offset,
          limit,
        },
        { cancelToken: token }
      );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      if (!data) throw new Error("No data");

      const reports = data.map((item) => ({
        __typename: "distributorReport",
        ...transformObjectKeys(item),
        type,
      }));

      return {
        kind: "ok",
        reports,
      };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async sendUserVisits(data: UserVisitsData): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/api/statistics/visits`,
      data
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      return { kind: "ok" };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async sendUserActivity(data: UserActivityData): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/api/statistics/pings`,
      data
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      return { kind: "ok" };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async updateUserPassword(newPassword: string): Promise<any> {
    // const response: ApiResponse<any> = await this.apisauce.post(
    //   /api`,
    //   newPassword
    // );

    return { kind: "ok" };
  }

  async getCustomerCompanies(token?: CancelToken): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v2/customer_companies",
      undefined,
      { cancelToken: token }
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const customerCompanies = transformedData.map((company: any) => ({
        __typename: "customerCompany",
        ...company,
      }));
      return { kind: "ok", customerCompanies };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async processLessons(ids: string[]): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/schedules-service/api/v1/lessons/technical_cancellation`,
      {
        ids,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) {
        return problem;
      }
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const lessons = transformedData.lessons.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ teacherIds, ...lesson }: any) => ({
          ...lesson,
          __typename: "lesson",
          index: lesson.number,
          startAtLocal: utcToZonedTime(
            lesson.startAt.split("+")[0],
            currentTimeZone
          ),
          endAtLocal: utcToZonedTime(
            lesson.endAt.split("+")[0],
            currentTimeZone
          ),
          unit: lesson.unitIds?.[0] ? String(lesson?.unitIds?.[0]) : undefined,
          status: camelCase(lesson.status || ""),
          attendance: lesson.attendance?.map((attendance: any) => ({
            ...attendance,
          })),
        })
      );

      return {
        kind: "ok",
        lessons,
      };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getUnit_v3(unitId: string): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.get(
      `/api/v3/units/${unitId}`
    );

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);

      const unit = {
        __typename: "unit",
        id: unitId,
        blocks: transformedData.blocks.map((block: any) => ({
          ...block,
          __typename: "block",
          unit: unitId,
          additionalContent: block.groupInfos?.[0]?.additionalContent
            ?.elements?.[0] && {
            ...block.groupInfos[0].additionalContent.elements[0],
            script: block.groupInfos[0].additionalContent.elements[0].script
              ?.filter((scriptItem: any) => scriptItem.id)
              ?.map((item: any) => ({
                ...item,
                text: item?.text?.replace(//g, "'"), // Замена неправильного символа на апостроф
              })),
          },
          order: block.passing?.content.order,
        })),
        passing: transformedData.unitPassing,
      };

      return { kind: "ok", unit };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getBlock_v3(blockId: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/api/v3/blocks/${blockId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);
      const block = {
        __typename: "block",
        id: blockId,
        exercises: transformedData.exercises.map((exercise: any) =>
          typecheckExercise({
            ...exercise,
            __typename: "exercise",
            state: camelCase(exercise.state),
            block: blockId,
            additionalContent: exercise.additionalContent?.elements?.map(
              (element: any) => ({
                ...element,
                __notDeflate: true,
              })
            ),
            correctAnswers: exercise.correctAnswers?.map(
              (correctAnswer: any) => ({
                ...correctAnswer,
                __notDeflate: true,
                questionId: String(correctAnswer.questionId),
                answers: correctAnswer.answers?.map((answer: any) => ({
                  ...answer,
                  diff: answer.diff?.flat(),
                })),
              })
            ),
            questions: exercise.questions?.map((question: any) => ({
              ...question,
              __notDeflate: true,
            })),
            questionsRaw: exercise.questionsRaw?.map((questionRaw: any) => ({
              ...questionRaw,
              __notDeflate: true,
              id: String(questionRaw.id ?? questionRaw.q_id),
            })),
            possibleAnswers: exercise.possibleAnswers?.map(
              (possibleAnswer: any) => ({
                ...possibleAnswer,
                __notDeflate: true,
              })
            ),
            passingData: exercise.passingData?.map((item: any) => ({
              ...item,
              __notDeflate: true,
              state: camelCase(item.state),
              answers:
                item.userAnswer?.answers?.[0] instanceof Array
                  ? item.userAnswer.answers.flat()
                  : item.userAnswer?.answers?.map((answer: any) => ({
                      ...answer,
                      state: answer.state ? camelCase(answer.state) : null,
                    })) || [item.userAnswer],
            })),
            partGroups: exercise.partGroups?.[0].map((partGroup: any) => ({
              ...partGroup,
              __notDeflate: true,
            })),
          })
        ),
      };

      return { kind: "ok", block };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getNotifications() {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/api/v3/notifications"
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const { data } = response;
      const transformedData = transformObjectKeys(data);

      const notifications = {
        __typename: "notifications",
        ...transformedData.notifications,
        groups: transformedData.notifications.groups.map((group: any) => ({
          ...group,
          notifications: group.notifications.map((notification: any) => ({
            ...notification,
            taskId: getNotificationTaskId(notification.url),
          })),
        })),
      };

      return { kind: "ok", data: notifications };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async markNotificationAsRead(notificationId: string) {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/api/v3/notifications/${notificationId}/was_read`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) {
        return problem;
      }
    }

    try {
      const { data } = response;

      return { kind: "ok", data };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async checkExercise({
    coursePassingId,
    exerciseId,
    answersData,
  }: CheckExerciseProps) {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/api/v2/exercises/${exerciseId}/check`,
      answersData instanceof FormData
        ? answersData
        : {
            answers_data: answersData,
          }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) {
        return problem;
      }
    }

    try {
      const {
        passing: exerciseData,
        unitPassing,
        cefrLevel,
        courseMark,
      } = transformObjectKeys(response.data);

      const data = {
        exercise: typecheckExercise({
          ...exerciseData,
          __typename: "exercise",
          state: camelCase(exerciseData.state),
          block: exerciseData.blockId,
          additionalContent: exerciseData.additionalContent?.elements?.map(
            (element: any) => ({
              ...element,
              __notDeflate: true,
            })
          ),
          correctAnswers: exerciseData.correctAnswers?.map(
            (correctAnswer: any) => ({
              ...correctAnswer,
              __notDeflate: true,
              questionId: String(correctAnswer.questionId),
              answers: correctAnswer.answers?.map((answer: any) => ({
                ...answer,
                diff: answer.diff?.flat(),
              })),
            })
          ),
          questions: exerciseData.questions?.map((question: any) => ({
            ...question,
            __notDeflate: true,
          })),
          questionsRaw: exerciseData.questionsRaw?.map((questionRaw: any) => ({
            ...questionRaw,
            __notDeflate: true,
            id: String(questionRaw.id ?? questionRaw.q_id),
          })),
          possibleAnswers: exerciseData.possibleAnswers?.map(
            (possibleAnswer: any) => ({
              ...possibleAnswer,
              __notDeflate: true,
            })
          ),
          passingData: exerciseData.passingData?.map((item: any) => ({
            ...item,
            __notDeflate: true,
            state: camelCase(item.state),
            answers:
              item.userAnswer?.answers?.[0] instanceof Array
                ? item.userAnswer.answers.flat()
                : item.userAnswer?.answers?.map((answer: any) => ({
                    ...answer,
                    state: answer.state ? camelCase(answer.state) : null,
                  })) || [item.userAnswer],
          })),
          partGroups: exerciseData.partGroups?.[0].map((partGroup: any) => ({
            ...partGroup,
            __notDeflate: true,
          })),
        }),
        passing: {
          __typename: "passing",
          id: coursePassingId,
          cefrLevel,
          courseMark,
        },
      };

      if (unitPassing) {
        Object.assign(data, {
          unit: {
            __typename: "unit",
            id: String(unitPassing.contentId),
            passing: unitPassing,
          },
        });
      }

      return { kind: "ok", data };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async resetExercise(exerciseId: string) {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/api/v2/exercises/${exerciseId}/reset_passing`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) {
        return problem;
      }
    }

    try {
      const { data } = response;
      const { exercise: exerciseData } = transformObjectKeys(data);
      const exercise = typecheckExercise({
        ...exerciseData,
        __typename: "exercise",
        state: camelCase(exerciseData.state),
        block: exerciseData.blockId,
        additionalContent: exerciseData.additionalContent?.elements?.map(
          (element: any) => ({
            ...element,
            __notDeflate: true,
          })
        ),
        questions: exerciseData.questions.map((question: any) => ({
          ...question,
          __notDeflate: true,
        })),
        questionsRaw: exerciseData.questionsRaw?.map((questionRaw: any) => ({
          ...questionRaw,
          __notDeflate: true,
          id: String(questionRaw.id ?? questionRaw.q_id),
        })),
        possibleAnswers: exerciseData.possibleAnswers?.map(
          (possibleAnswer: any) => ({
            ...possibleAnswer,
            __notDeflate: true,
          })
        ),
        correctAnswers: [],
        passingData: [],
        partGroups: exerciseData.partGroups?.[0].map((partGroup: any) => ({
          ...partGroup,
          __notDeflate: true,
        })),
      });

      return { kind: "ok", exercise };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async addWords(formData: FormData): Promise<Types.AddWords> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        `/learning_words`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const { data } = response;
      const transformedData = transformObjectKeys(data);

      const allAddedWords = [
        ...transformedData.successful,
        ...transformedData.withErrors,
      ];

      const addedWords: Types.AddedWord[] = allAddedWords.map((word) => ({
        word: word.header,
        translation: word.translation,
        partOfSpeech: word.partOfSpeech,
        addedBefore: word.errors,
      }));

      return { kind: "ok", data: addedWords };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async saveRecording(
    exerciseId: string,
    questionId: string,
    formData: FormData
  ): Promise<Types.SaveRecoding> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        `/api/v2/exercises/${exerciseId}/save_answer_record/${questionId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);
      const data: Types.SavedRecording = {
        questionId: String(transformedData.question.id),
        recordUrl: transformedData.question.userAnswer.recordUrl,
      };

      return { kind: "ok", data };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async preprocessSpeakingQuestion(
    exerciseId: string,
    questionId: string,
    formData: FormData
  ): Promise<Types.PreprocessSpeakingQuestion> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        `/api/v2/exercises/${exerciseId}/preprocess_speaking_question/${questionId}?language=en`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getTeachersGroupsV3(): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        `/api/v3/teacher/groups `
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);
      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async entranceTest(id: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        `/api/v3/testings/${id}`
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const data = {
        ...transformedData,
        id: "0",
        courseId: toStringOrNull(transformedData.courseId),
        unitId: toStringOrNull(transformedData.unitId),
        blockId: toStringOrNull(transformedData.blockId),
        exerciseId: toStringOrNull(transformedData.exerciseId),
      };

      return { kind: "ok", data };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getTaggingList(id: string) {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        `/payroll-service/api/v1/tagging/lists/${id}`
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const { data: list } = transformObjectKeys(response.data);

      const taggingList = {
        ...list,
        __typename: "taggingList",
        id: String(list.id),
        tags: list?.tags?.map((tag: any) => ({
          ...tag,
          __typename: "tag",
          id: String(tag.id),
          listId: String(tag.listId),
        })),
      };

      return { kind: "ok", data: taggingList };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getTaggingLists(area?: "teacher") {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/payroll-service/api/v1/tagging/lists",
        { "filter[area]": area }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const taggingLists = transformedData?.data?.map((list: any) => ({
        ...list,
        __typename: "taggingList",
        id: String(list.id),
        tags: list?.tags?.map((tag: any) => ({
          ...tag,
          __typename: "tag",
          id: String(tag.id),
          listId: String(tag.listId),
        })),
      }));

      return { kind: "ok", data: taggingLists };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getTaggingTags() {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/payroll-service/api/v1/tagging/tags"
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);
      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async updateTag(tagId: string, data: Types.TagUpdateData) {
    try {
      const response: ApiResponse<any> = await this.apisauce.put(
        `/payroll-service/api/v1/tagging/tags/${tagId}`,
        data
      );

      return { kind: "ok", id: response.data?.id };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async createTag(listId: string, data: Types.TagCreateData): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        "/payroll-service/api/v1/tagging/tags",
        { list_id: listId, ...data }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const { data: tag } = transformedData;

      const responseData = {
        ...tag,
        id: String(tag.id),
        listId: String(tag.listId),
      };

      return { kind: "ok", data: responseData };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async deleteTag(tagId: string) {
    try {
      const response: ApiResponse<any> = await this.apisauce.delete(
        `/payroll-service/api/v1/tagging/tags/${tagId}`
      );

      return { kind: "ok", data: tagId };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getTeacherTags(teacherId: string) {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/payroll-service/api/v1/teacher_tags",
        { "filter[teacher_id]": teacherId }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const data = {
        __typename: "teacher",
        id: teacherId,
        actualId: teacherId,
        tags: transformedData?.data?.map((item: any) => String(item.tagId)),
      };

      return { kind: "ok", data };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async updateTeacherTags(teacherId: string, tagIds: string[]) {
    try {
      const response: ApiResponse<any> = await this.apisauce.put(
        `/payroll-service/api/v1/teachers/${teacherId}`,
        {
          teacher: {
            tag_ids: tagIds.map((id) => Number(id)),
          },
        }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);
      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getTeacherRates(params: Types.GetTeacherRates, token?: CancelToken) {
    try {
      const { teacherId, classType, limit, offset } = params;

      const response: ApiResponse<any> = await this.apisauce.get(
        "/payroll-service/api/v1/teacher_rates",
        {
          "filter[teacher_id]": teacherId,
          "filter[class_type]": classType,
          limit,
          offset,
        },
        { cancelToken: token }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const teacherRates = transformedData?.data?.map((teacherRate: any) => ({
        ...teacherRate,
        __typename: "teacherRate",
        __notDeflate: true,
        id: String(teacherRate.id),
        teacherId: String(teacherRate.teacherId),
        languageId: String(teacherRate.languageId),
      }));

      return { kind: "ok", data: teacherRates };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async createTeacherRate(data: Types.TeacherRateCreateData): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        "/payroll-service/api/v1/teacher_rates",
        {
          teacher_rate: mapKeys(data, (_, key) => snakeCase(key)),
        }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const teacherRates = {
        ...transformedData?.data,
        __typename: "teacherRate",
        __notDeflate: true,
        id: String(transformedData?.data.id),
        teacherId: String(transformedData?.data.teacherId),
        languageId: String(transformedData?.data.languageId),
        activeFrom: new Date(transformedData?.data.activeFrom),
      };

      return { kind: "ok", data: teacherRates };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async updateTeacherRate(rateId: string, data: Types.TeacherRateUpdateData) {
    try {
      const response: ApiResponse<any> = await this.apisauce.put(
        `/payroll-service/api/v1/teacher_rates/${rateId}`,
        mapKeys(data, (_, key) => snakeCase(key))
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      return { kind: "ok", data: transformedData.data };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getTeacher(teacherId: string, token?: CancelToken) {
    try {
      const data = {
        __typename: "teacher",
        id: teacherId,
        actualId: teacherId,
        firstName: "Константин",
        middleName: "Константинович",
        lastName: "Константинопольский",
        photo:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAUQbM0coQ3Oygepmz8mdI9fvcxVKiGku3h3QW0IUmhQ&s",
        email: "kkonstantinopolski90@yandex.ru",
        phone: "+7-968-110-39-32",
      };

      return { kind: "ok", data };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async confirmEmail(confirmationToken: string) {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        "/api/v3/users/confirm",
        { user: { confirmation_token: confirmationToken } }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getLanguages(token?: CancelToken) {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/api/v3/languages",
        undefined,
        { cancelToken: token }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const languages = transformedData.map((lang: any) => ({
        ...lang,
        __typename: "language",
      }));

      return { kind: "ok", data: languages };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getCountries(): Promise<Types.GetCountries> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/api/v3/profile/countries"
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getCities(): Promise<Types.GetCities> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/api/v3/profile/cities"
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getDegrees(): Promise<Types.GetDegrees> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/api/v3/profile/degrees"
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      return { kind: "ok", data: transformedData };
    } catch (error) {
      console.error(error);
      return { kind: "bad-data", message: error };
    }
  }

  async getClassGroups(
    { groupIds }: Types.GetLearningGroupApprovesParams,
    token?: CancelToken
  ): Promise<any> {
    try {
      const params = {
        ...(groupIds && {
          ids: groupIds,
        }),
      };

      const response: ApiResponse<any> = await this.apisauce.get(
        "/schedules-service/api/v1/class_groups",
        params,
        { cancelToken: token }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const classGroups = transformedData.data.map((classGroup: any) => ({
        ...classGroup,
        __typename: "classGroup",
        course: String(classGroup.courseId),
        lessonsPerUnit: Number(classGroup.lessonsPerUnit),
        classSchedules: classGroup.classSchedules?.map((schedules: any) => ({
          ...schedules,
          __typename: "classSchedule",
          classGroup: String(classGroup.id),
          teachersIds: schedules.teacherIds?.map(String),
          course: String(classGroup.courseId),
        })),
      }));

      return { kind: "ok", data: classGroups };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getLearningGroupApproves(
    { groupIds }: Types.GetLearningGroupApprovesParams,
    token?: CancelToken
  ): Promise<any> {
    try {
      const params = {
        ...(groupIds && {
          group_ids: groupIds,
        }),
      };

      const response: ApiResponse<any> = await this.apisauce.get(
        "/payroll-service/api/v1/group_approves",
        params,
        { cancelToken: token }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const { data } = response;

      const transformedData = transformObjectKeys(data.data);

      const learningGroupApproves = transformedData.map((approve: any) => ({
        ...approve,
        __typename: "learningGroupApprove",
        group: approve.groupId,
        user: approve.userId,
      }));

      return { kind: "ok", data: learningGroupApproves };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async approveLearningGroup(id: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        `/payroll-service/api/v1/groups/${id}/approve`
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const { data } = response;

      const transformedData = transformObjectKeys(data);

      const approve = {
        ...transformedData,
        __typename: "learningGroupApprove",
        group: transformedData.groupId,
        user: transformedData.userId,
      };

      return { kind: "ok", data: approve };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getLearningGroupSurcharges(
    { groupIds }: Types.GetLearningGroupApprovesParams,
    token?: CancelToken
  ): Promise<any> {
    try {
      const params = {
        ...(groupIds && {
          group_ids: groupIds,
        }),
      };

      const response: ApiResponse<any> = await this.apisauce.get(
        "/payroll-service/api/v1/surcharges",
        params,
        { cancelToken: token }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const surcharges = transformedData.data.map((surcharge: any) => ({
        ...surcharge,
        __typename: "surcharge",
        group: surcharge.groupId,
      }));

      return { kind: "ok", data: surcharges };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async createSurcharge(data: Types.SurchargeCreateData): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        "/payroll-service/api/v1/surcharges",
        {
          surcharge: mapKeys(data, (_, key) => snakeCase(key)),
        }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      const surcharge = {
        ...transformedData.data,
        __typename: "surcharge",
        group: transformedData.data.groupId,
      };

      return { kind: "ok", data: surcharge };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async updateSurcharge(
    id: string,
    data: Types.SurchargeCreateData
  ): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.put(
        `/payroll-service/api/v1/surcharges/${id}`,
        {
          surcharge: mapKeys(data, (_, key) => snakeCase(key)),
        }
      );

      return { kind: "ok", id: response.data.id };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async deleteSurcharge(id: string): Promise<any> {
    try {
      const response: ApiResponse<LessonUpdate> = await this.apisauce.delete(
        `/payroll-service/api/v1/surcharges/${id}`
      );

      return { kind: "ok" };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getCompaniesReport(
    params: Types.GetCompaniesReport,
    token?: CancelToken
  ): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/reports-service/api/v1/payroll_summary_by_company_reports",
        mapKeys(params, (_, key) => snakeCase(key)),
        { cancelToken: token }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);
      const companiesReport = transformedData.items
        .map((report: any) => ({
          __typename: "companyReport",
          ...report,
          id: String(report.company.id),
          company: String(report.company.id),
        }))
        .concat([
          {
            __typename: "companyReport",
            id: "summary",
            ...transformedData.summary,
          },
        ]);

      return { kind: "ok", data: companiesReport };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async getCancellationReport(
    params: Types.GetCancellationReport,
    token?: CancelToken
  ): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        "/reports-service/api/v1/lesson_counters/by_teacher",
        mapKeys(params, (_, key) => snakeCase(key)),
        { cancelToken: token }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);
      const cancellationReports = transformedData
        .filter((report: any) => report.teacherInfo.id !== "110")
        .map((report: any, i: number) => ({
          __typename: "cancellationReport",
          id: String(i),
          teacher: report.teacherInfo.id,
          countersByCompany: report.countersByCompany.map((counters: any) => ({
            company: counters.companyInfo.id,
            counters: counters.counters.map((counter: any) => ({
              ...counter,
              hours: Number(counter.hours),
              status: camelCase(counter.status),
            })),
          })),
        }));

      return { kind: "ok", data: cancellationReports };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }

  async checkPromocode(
    promocode: string,
    token?: CancelToken
  ): Promise<Types.CheckPromocode> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(
        `/api/v3/promocodes/${promocode}`,
        { cancelToken: token }
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const transformedData = transformObjectKeys(response.data);

      return { kind: "ok", data: { ...transformedData, promocode } };
    } catch (error) {
      return { kind: "bad-data", message: error };
    }
  }
}
