import { useQuery } from "@tanstack/react-query";

import { getCourses, getCourse } from "./index";

export const useGetCourses = () => {
  return useQuery({
    queryKey: ["courses"],
    queryFn: getCourses,
  });
};

export const useGetCourse = (courseId?: string) => {
  return useQuery({
    queryKey: ["courseDetails", courseId],
    queryFn: () => getCourse(courseId ?? ""),
    enabled: !!courseId,
  });
};
