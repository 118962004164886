import {
  ReportResponse,
  ReportsResponse,
  transformReportResponse,
  DistributorReportType,
} from "./types";
import { api } from "../api";

export interface DeleteReportsParams {
  ids: string[];
  type: DistributorReportType;
}

export interface CreateReportParams {
  title: string;
  type: string;
  groups: string[];
  from: string | null;
  to: string | null;
}

export interface SendReportsToMailParams {
  ids: string[];
}

export const getReports = (type: DistributorReportType) => {
  return api
    .get<ReportsResponse>(`/api/v3/reports?filter[type]=${type}`)
    .then((res) => res.data);
};

export const getReport = (reportId: string) => {
  return api
    .get<ReportResponse>(`/api/v3/reports/${reportId}`)
    .then((res) => transformReportResponse(res.data));
};

export const deleteReports = (params: DeleteReportsParams) => {
  return api.delete(`/api/v3/reports`, { params });
};

export const createReport = (params: CreateReportParams) => {
  const { title: name, type, from: date_from, to: date_to, groups } = params;

  return api
    .post<ReportsResponse>("/api/v3/reports/", {
      name,
      type,
      date_from,
      date_to,
      parameters: {
        groups,
      },
    })
    .then((res) => res.data);
};

export const sendReportsToMail = (params: SendReportsToMailParams) => {
  const { ids } = params;

  return api.post("/api/v3/reports/to_email", { ids });
};
