import { forwardRef } from "react";

import { BasicInput, BasicInputProps } from "../BasicInput";
import { IconType } from "../Icon";

export type StandardInputProps = {
  type: InputType;
} & BasicInputProps;

type InputType = "email" | "text" | "number" | "name";

export const StandardInput = forwardRef<HTMLInputElement, StandardInputProps>(
  function StandardInput(props, ref) {
    const { type } = props;

    const icons: Partial<Record<InputType, IconType>> = {
      email: "input-email",
      name: "input-person",
    };

    return <BasicInput ref={ref} leftIcon={icons[type]} {...props} />;
  }
);
