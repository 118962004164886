import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import Modal from "react-modal";

import { useGetCourse } from "@packages/data/courses";

import { ReportModalData } from "../../types";
import { Icon } from "../Icon";
import { Loading } from "../Loading";
import { ProgressBar } from "../ProgressBar";
import { ReportDetailsBlock } from "../ReportDetailsBlock";
import { Text } from "../Text";

import styles from "./ReportDetails.module.scss";

interface ReportDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  data: ReportModalData | null;
}

Modal.setAppElement("body");

export const ReportDetails = (props: ReportDetailsProps) => {
  const { isOpen, onClose, data } = props;

  const { t } = useTranslation();

  const { data: course, isLoading, isError } = useGetCourse(data?.courseId);

  const unit = useMemo(
    () => course?.units.find((unit) => unit.id === data?.unitId),
    [course, data]
  );

  const getPrecessing = () => {
    if (!unit || !data) return 0;

    if (data.variant === "test") {
      return data.record.unitPassings[unit.id].processingTestPercent;
    }

    return data.record.unitPassings[unit.id].processingPercent;
  };

  const getProgress = () => {
    if (!unit || !data) return 0;

    if (data.variant === "test") {
      return data.record.unitPassings[unit.id].progressTestPercent;
    }

    return data.record.unitPassings[unit.id].progressPercent;
  };

  const getBlockPrecessing = (blockId: string) => {
    if (!unit || !data) return 0;

    if (data.variant === "test") {
      return data.record.blockPassings[blockId].processingTestPercent;
    }

    return data.record.blockPassings[blockId].processingPercent;
  };

  const getBlockProgress = (blockId: string) => {
    if (!unit || !data) return 0;

    if (data.variant === "test") {
      return data.record.blockPassings[blockId].progressTestPercent;
    }

    return data.record.blockPassings[blockId].progressPercent;
  };

  const getExerciseProcessing = (exerciseId: string) => {
    if (!unit || !data) return 0;

    return data.record.exercisePassings[exerciseId].processingPercent;
  };

  if (!unit) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={{
        base: styles["modal-body"],
        afterOpen: styles["modal-body--after-open"],
        beforeClose: styles["modal-body--before-close"],
      }}
      overlayClassName={styles["modal-overlay"]}
      closeTimeoutMS={100}
      // Отключение скролла при открытии модального окна
      onAfterOpen={() => {
        document.body.style.overflow = "hidden";
      }}
      onAfterClose={() => {
        document.body.style.overflow = "unset";
      }}
    >
      <Loading loading={isLoading} error={isError}>
        <div className={styles.header}>
          <div>
            <Text variant="h2-mob">{unit?.name}: </Text>
            <Text variant="h3-mob">
              {t(
                `TeacherReport:${
                  data?.variant === "normal" ? "HeaderNormal" : "HeaderTest"
                }`
              )}
            </Text>
          </div>

          <button type="button" onClick={onClose}>
            <Icon icon="close-modal" />
          </button>
        </div>

        <div className={styles.progress}>
          <ProgressBar
            type="percent"
            backgroundColor="grey"
            barColor="blue"
            progress={getProgress()}
            subtitleAlign="left"
            subtitle={t("TeacherReport:HeaderCompleted") ?? ""}
          />
          <ProgressBar
            type="percent"
            backgroundColor="grey"
            barColor="green"
            progress={getPrecessing()}
            subtitleAlign="left"
            subtitle={t("TeacherReport:HeaderResult") ?? ""}
          />
        </div>

        <div className={styles["blocks-container"]}>
          <div className={styles["blocks-title"]}>
            <Text variant="text-2-semibold">{t("TeacherReport:Blocks")}</Text>
          </div>

          {unit.blocks.map((block) => (
            <ReportDetailsBlock
              key={block.id}
              data={block}
              precessing={getBlockPrecessing(block.id)}
              progress={getBlockProgress(block.id)}
              getExerciseProcessing={getExerciseProcessing}
            />
          ))}
        </div>
      </Loading>
    </Modal>
  );
};
