import { useState, ChangeEvent, useEffect, useMemo } from "react";

import { useTheme } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";

import { ListModules } from "components/ListModules";
import { Loading } from "components/Loading";
import { Search } from "components/Search";
import { Select } from "components/Select";
import { levels } from "constants/constants";
import { getLocalName } from "utils/basic";

import { filterByLevel, filterByName } from "./helpers/filterCourse";
import {
  MyModulesTopContent,
  SelectContent,
  TitleModules,
  WrapModuleContent,
} from "./styledComponents";

export const MyModules = observer((): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [search, setSearch] = useState("");
  const [level, setLevel] = useState("");

  const store = useStores();

  const { learningGroup: learningGroupStore, course: courseStore, api } = store;

  const { activeCoursesWithoutGroup } = courseStore;

  const { activeCourses } = learningGroupStore;

  const {
    fetch: fetchGroups,
    loading: groupsLoading,
    error: groupsError,
  } = useCancelableFetch(true);

  const {
    fetch: fetchCourses,
    loading: coursesLoading,
    error: coursesError,
  } = useCancelableFetch(true);

  const {
    fetch: fetchCoursePassings,
    loading: coursePassingsLoading,
    error: coursePassingsError,
  } = useCancelableFetch(true);

  const isLoading = groupsLoading || coursesLoading || coursePassingsLoading;
  const isError = Boolean(groupsError || coursesError || coursePassingsError);

  const handleChangeSearch = _.debounce(
    (e: ChangeEvent<HTMLInputElement>): void => setSearch(e.target.value),
    750
  );

  const handleChangeLevel = (e: SelectChangeEvent<unknown>): void =>
    setLevel(e.target.value as string);

  const sortedCourses = useMemo(() => {
    const filteredCourses = [...activeCourses, ...activeCoursesWithoutGroup]
      .filter(filterByName(search))
      .filter(filterByLevel(level));

    return _.sortBy(filteredCourses, (item) => [
      [item?.knowledgeLevel?.code, getLocalName(item.translationsAttributes)],
    ]);
  }, [activeCourses, activeCoursesWithoutGroup, level, search]);

  useEffect(() => {
    fetchGroups((token) => api.getLearningGroups({}, token));
    fetchCourses((token) => api.getCourses(token));
    fetchCoursePassings((token) => api.getCoursePassings(token));
  }, [api, fetchCoursePassings, fetchCourses, fetchGroups]);

  return (
    <WrapModuleContent>
      <MyModulesTopContent>
        <TitleModules variant="h1">{t(`MyModules:title`)}</TitleModules>

        <SelectContent>
          <Search
            inputProps={{
              style: {
                fontSize: theme.spacing(4),
              },
            }}
            onChange={handleChangeSearch}
          />
          <Select
            data={levels}
            placeholder={t(`MyModules:levelPlaceholder`)}
            onChange={handleChangeLevel}
            name=""
          />
        </SelectContent>
      </MyModulesTopContent>

      <Loading loading={isLoading} error={isError}>
        <ListModules isBottom countPage={10} courses={sortedCourses} />
      </Loading>
    </WrapModuleContent>
  );
});
