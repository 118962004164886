import {
  SelectHTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import cn from "classnames";
import { createPortal } from "react-dom";

import { SelectItem, SelectItemData } from "./SelectItem";
import { useDropdown } from "../../hooks/useDropdown";
import { Icon, IconType } from "../Icon";
import { Input } from "../Input";
import { Text } from "../Text";

import styles from "./Select.module.scss";

type SelectProps = {
  containerClassName?: string;
  placeholder?: string;
  items?: SelectItemData[];
  value?: string;
  onChange?: (_: string | undefined) => void;
  searchable?: boolean;
  clearable?: boolean;
  variant?: "primary" | "secondary";
  fullWidth?: boolean;
  error?: string;
} & Omit<SelectHTMLAttributes<string>, "onChange">;

export const Select = forwardRef<HTMLInputElement, SelectProps>(function Select(
  props,
  ref
) {
  const {
    containerClassName,
    placeholder,
    items = [],
    value = "",
    onChange,
    searchable = false,
    clearable = false,
    variant = "primary",
    fullWidth = false,
    error,
    autoComplete,
    disabled,
  } = props;

  const {
    containerRef: dropdownContainerRef,
    dropdownRef,
    isOpen: isDropdownOpen,
    toggle: toggleDropdown,
    close: closeDropdown,
    styles: dropdownStyles,
  } = useDropdown<HTMLDivElement>();

  const [inputValue, setInputValue] = useState(value);

  const [currentValue, setCurrentValue] = useState(value);

  const selectedItem = items?.find(({ value }) => value === currentValue);
  const selectedText = selectedItem?.label ?? placeholder;

  const hasError = !!error;

  const containerClasses = cn(styles.container, containerClassName, {
    [styles["full-width"]]: fullWidth,
  });
  const selectClasses = cn(styles[`select-${variant}`], {
    [styles["full-width"]]: fullWidth,
    [styles[`select-${variant}-disabled`]]: disabled,
  });
  const selectedTextClasses = cn(styles["selected-text"], {
    [styles["selected-text-placeholder"]]: !selectedItem,
    [styles["selected-text-error"]]: hasError,
    [styles["selected-text-disabled"]]: disabled,
  });

  const selectIcon: IconType = isDropdownOpen ? "select-up" : "select-down";

  const handleSelectClick = () => {
    if (disabled) return;

    toggleDropdown();
  };

  const handleItemClick = useCallback(
    (value: string) => {
      closeDropdown();
      setCurrentValue(value);
      setInputValue(items.find((item) => item.value === value)?.label ?? "");
      onChange?.(value);
    },
    [closeDropdown, items, onChange]
  );

  const handleInputValueChange = useCallback(
    (value: string) => {
      setInputValue(value);
      onChange?.(undefined);
    },
    [onChange]
  );

  const handleClear = () => {
    onChange?.(undefined);
    setCurrentValue("");
    setInputValue("");
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value, setCurrentValue]);

  const List = useMemo(() => {
    return items
      .filter((item) => {
        if (searchable) {
          return item.label
            .trim()
            .toLowerCase()
            .includes(inputValue.trim().toLowerCase());
        }

        return true;
      })
      .map((item) => (
        <SelectItem
          key={item.value}
          item={item}
          selected={item.value === currentValue}
          onClick={handleItemClick}
          variant={variant}
        />
      ));
  }, [items, searchable, inputValue, currentValue, handleItemClick, variant]);

  return (
    <div ref={dropdownContainerRef} className={containerClasses}>
      <div className={selectClasses} onClick={handleSelectClick}>
        {searchable ? (
          <Input
            ref={ref}
            type="text"
            containerClassName={selectedTextClasses}
            wrapperClassName={styles["input-wrapper"]}
            value={inputValue}
            onChange={(e) => handleInputValueChange(e.target.value)}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            variant={variant}
          />
        ) : (
          <Text
            containerClassName={selectedTextClasses}
            variant="text-3-medium"
          >
            {selectedText}
          </Text>
        )}

        {clearable && !!inputValue && (
          <button type="button" onClick={handleClear}>
            <Icon containerClassName={styles.clear} icon="close-filters" />
          </button>
        )}

        <Icon icon={selectIcon} />
      </div>
      {isDropdownOpen &&
        createPortal(
          <div
            ref={dropdownRef}
            className={styles[`item-list-${variant}`]}
            style={dropdownStyles}
          >
            {List}
          </div>,
          document.body
        )}

      {hasError && (
        <div className={styles["error-wrapper"]}>
          <Icon icon="input-error" />
          <Text
            containerClassName={styles["error-text"]}
            variant="text-3-medium"
            color="accsent-wd"
          >
            {error}
          </Text>
        </div>
      )}
    </div>
  );
});
