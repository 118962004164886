import cn from "classnames";

import styles from "./SteppedProgressBar.module.scss";

export interface StepItemProps {
  filled?: boolean;
  fillColor?: FillColor;
  backgroundColor?: BackgroundColor;
}

export type FillColor = "purple" | "yellow";

export type BackgroundColor = "white" | "gray";

export const StepItem = function StepItem(props: StepItemProps) {
  const { filled, fillColor = "gray", backgroundColor = "green" } = props;

  const itemClasses = cn(
    styles.item,
    styles[fillColor],
    styles[backgroundColor],
    {
      [styles["filled"]]: filled,
    }
  );

  return <div className={itemClasses} />;
};
