import { ReactNode, useMemo } from "react";

import cn from "classnames";

import {
  PercentageProgressBar,
  PercentageProgressBarProps,
} from "./PercentageProgressBar";
import {
  SteppedProgressBar,
  SteppedProgressBarProps,
} from "./SteppedProgressBar";

import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  containerClassName?: string;
  type: ProgressBarType;
}

type ProgressBarType = "percent" | "stepped";

type ConditionalProps = PercentageProgressBarProps | SteppedProgressBarProps;

export const ProgressBar = function ProgressBar(
  props: ProgressBarProps & ConditionalProps
) {
  const { containerClassName, type, ...barProps } = props;

  const containerClasses = cn(styles.container, containerClassName);

  const ProgressView: Record<ProgressBarType, ReactNode> = useMemo(
    () => ({
      percent: (
        <PercentageProgressBar {...(barProps as PercentageProgressBarProps)} />
      ),
      stepped: (
        <SteppedProgressBar {...(barProps as SteppedProgressBarProps)} />
      ),
    }),
    [barProps]
  );

  return <div className={containerClasses}>{ProgressView[type]}</div>;
};
