import cn from "classnames";

import { Icon } from "../Icon";
import { ReportContentData } from "../ReportTable";
import { Text } from "../Text";

import styles from "./ReportContentBody.module.scss";

interface ReportContentBodyProps {
  passing?: ReportContentData;
  disabled?: boolean;
  onClick?: (variant: "test" | "normal") => void;
}

export const ReportContentBody = function ReportContentBody(
  props: ReportContentBodyProps
) {
  const { passing, disabled = false, onClick } = props;

  const containerClassName = cn(styles["result-container"], {
    [styles["result-container-enabled"]]: !disabled,
  });

  const getText = (value: number | null) => {
    return (
      <Text variant={disabled ? "text-2-medium" : "text-2-semibold"}>
        {value === null ? "N/A" : `${value}%`}
      </Text>
    );
  };

  return (
    <div className={containerClassName}>
      <div
        className={styles["result-block-container"]}
        onClick={() => onClick?.("normal")}
      >
        {!disabled && (
          <Icon
            containerClassName={styles["result-icon"]}
            icon="stats"
            squareSize={14}
          />
        )}

        <div className={styles["result-block"]}>
          <div className={styles["result"]}>
            {getText(passing?.progressPercent ?? null)}
          </div>
          <div className={styles["result"]}>
            {getText(passing?.processingPercent ?? null)}
          </div>
        </div>
      </div>

      <div
        className={styles["result-block-container"]}
        onClick={() => onClick?.("test")}
      >
        {!disabled && (
          <Icon
            containerClassName={styles["result-icon"]}
            icon="stats"
            squareSize={14}
          />
        )}

        <div className={styles["result-block"]}>
          <div className={styles["result"]}>
            {getText(passing?.progressTestPercent ?? null)}
          </div>
          <div className={styles["result"]}>
            {getText(passing?.processingTestPercent ?? null)}
          </div>
        </div>
      </div>
    </div>
  );
};
