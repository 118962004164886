const tt = {
  TeacherReport: {
    Title: "Модуль буенча алгарыш",
    Students: "Студентлар",
    Total: "Барлыгы",
    HeaderTest: "Тест",
    HeaderNormal: "Уу-укыту",
    HeaderCompleted: "Үтәлгән",
    HeaderResult: "Нәтиҗә",
    Blocks: "Блоклар",
    StudentSelect: "Студентни танда",
    DeleteReport: "Сез ышанасызмы?",
    DeleteTooltip: "Бетерергә",
    DownloadTooltip: "Йөкләп алырга",
  },
  BlockTypes: {
    Vocabulary: "Сүзләр",
    Video: "Видео",
    Grammar: "Грамматика",
    Reading: "Уку",
    Writting: "Язу",
    Speaking: "Сөйләү",
    Test: "Тест",
  },
  Loading: {
    Error: "Йөкләүдә хата, битне яңартып карагыз",
  },
  SteppedProgress: {
    Completed: "Тәмамланган",
  },
};

export default tt;
