import { ReactNode } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Text } from "../Text";

import styles from "./Loading.module.scss";

interface LoadingProps {
  error?: boolean;
  errorLabel?: string;
  centerError?: boolean;

  loading?: boolean;
  children?: ReactNode;
  containerClassName?: string;
}

export const Loading = function Loading(props: LoadingProps) {
  const {
    containerClassName,
    children,
    loading,
    error = false,
    errorLabel,
    centerError = true,
  } = props;

  const { t } = useTranslation();

  if (error) {
    return (
      <div className={cn(styles.container, containerClassName)}>
        <Text alignment={centerError ? "center" : "left"} color="check-error">
          {errorLabel || t("Loading:Error")}
        </Text>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={cn(styles.container, containerClassName)}>
        <div className={styles.loading} />
      </div>
    );
  }

  return <>{children}</>;
};
