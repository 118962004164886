import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Report } from "@packages/data/reports";

import { ReportContentBody } from "../ReportContentBody";
import { ReportContentHeading } from "../ReportContentHeading";
import { Text } from "../Text";

import styles from "./ReportTable.module.scss";

export interface ReportContentData {
  processingTestPercent: number | null;
  progressTestPercent: number | null;
  processingPercent: number | null;
  progressPercent: number | null;
}

type Record = Report["data"][number]["records"][number];

interface ReportTableProps {
  title?: string;
  records: Record[];
  contentBlocks: {
    id: string;
    name: string;
  }[];
  getTotalPassing: (record: Record) => ReportContentData;
  getContentBlockPassings: (
    record: Record,
    contentBlockId: string
  ) => ReportContentData;
  onContentClick?: (
    record: Record,
    contentBlockId: string,
    variant: "test" | "normal"
  ) => void;
}

export const ReportTable = function ReportTable(props: ReportTableProps) {
  const {
    title,
    records,
    contentBlocks,
    getTotalPassing,
    getContentBlockPassings,
    onContentClick,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.unit}>
      {title && (
        <Text
          containerClassName={styles["unit-title"]}
          variant="text-1-semibold"
        >
          {title}
        </Text>
      )}

      <div className={styles["unit-content"]}>
        <div className={styles["unit-content-left"]}>
          <div
            className={cn(
              styles["unit-content-students"],
              styles["unit-content-left-item"]
            )}
          >
            <Text variant="text-2-semibold">{t("TeacherReport:Students")}</Text>
          </div>

          {records.map((record, i) => (
            <div key={i} className={styles["unit-content-left-item"]}>
              <div>
                <Text variant="text-2-semibold">{record.studentName}</Text>
              </div>

              <div>
                <Text variant="text-2-regular" color="base-dl">
                  {record.studentMail}
                </Text>
              </div>
            </div>
          ))}
        </div>

        <div className={styles["unit-content-right"]}>
          <div className={styles["unit-content-right-row"]}>
            <ReportContentHeading title={t("TeacherReport:Total")} />

            {contentBlocks.map((block) => (
              <ReportContentHeading key={block.id} title={block.name ?? ""} />
            ))}
          </div>

          {records.map((record, i) => (
            <div className={styles["unit-content-right-row"]} key={i}>
              <ReportContentBody disabled passing={getTotalPassing(record)} />

              {contentBlocks.map((block) => (
                <ReportContentBody
                  key={block.id}
                  passing={getContentBlockPassings(record, block.id)}
                  onClick={(variant) =>
                    onContentClick?.(record, block.id, variant)
                  }
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
