import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getReports,
  getReport,
  deleteReports,
  DistributorReportType,
  createReport,
  ReportsResponse,
  sendReportsToMail,
} from "./index";

export const useGetReports = (
  type: DistributorReportType,
  refetchInterval?: number
) => {
  return useQuery({
    queryKey: ["reports", type],
    queryFn: () => getReports(type),
    refetchInterval,
  });
};

export const useGetReport = (reportId: string) => {
  return useQuery({
    queryKey: ["reportDetails", reportId],
    queryFn: () => getReport(reportId),
  });
};

export const useDeleteReports = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteReports,
    // Optimistic update
    onMutate: async ({ ids, type }) => {
      await queryClient.cancelQueries({ queryKey: ["reports", type] });

      const previousReports = queryClient.getQueryData(["reports", type]);

      queryClient.setQueryData<ReportsResponse>(["reports", type], (old) => {
        if (!old) return old;

        return old.filter((report) => !ids.includes(report.id));
      });

      return { previousReports };
    },
    onError: (_, { type }, context) => {
      if (!context) return;

      queryClient.setQueryData(["reports", type], context.previousReports);
    },
    onSuccess: (_, { type }) => {
      queryClient.invalidateQueries({ queryKey: ["reports", type] });
    },
  });
};

export const useCreateReport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createReport,
    onSuccess: (_, { type }) => {
      queryClient.invalidateQueries({ queryKey: ["reports", type] });
    },
  });
};

export const useSendReportsToMail = () => {
  return useMutation({
    mutationFn: sendReportsToMail,
  });
};
